import { UPDATE_ZODIAC } from "../actions/types";
import { zodiacs } from "samples/zodiacs";

const initialState = {
  currentZodiac: 0,
  zodiacs,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_ZODIAC:
      return {
        ...state,
        currentZodiac: action.payload.currentZodiac,
      };

    default:
      return state;
  }
}
