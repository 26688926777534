import { UPDATE_ZODIAC } from "../actions/types";

export const updateZodiac = (currentZodiac) => (dispatch) => {
  dispatch({
    type: UPDATE_ZODIAC,
    payload: {
      currentZodiac,
    },
  });
};
