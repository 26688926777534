import 'retinajs';

import { Route, Switch } from 'react-router-dom';

import About from 'containers/About';
import Blog from 'containers/Blog';
import Contact from 'containers/Contact';
import Entry from 'containers/Entry';
import Footer from 'components/Footer';
import Home from 'containers/Home';
import Navbar from 'components/Navbar';
import NotFound from 'containers/NotFound';
import React from 'react';
import Shop from 'containers/Shop';

// App
const App = () => {
  return (
    <>
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/blog" exact component={Blog} />
        <Route path="/blog/:slug?" component={Entry} />
        <Route path="/shop/:zodiac?" component={Shop} />
        <Route path="/contact" component={Contact} />
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
