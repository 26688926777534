import React, { useState } from "react";
import { animated, useSpring } from "react-spring";

import BlogThumbnail from "components/BlogThumbnail";
import Pagination from "components/Pagination";
import VisibilitySensor from "react-visibility-sensor";
import { connect } from "react-redux";
import { useMemo } from "react";

const LatestEntries = (props) => {
  const { entries } = props.blog;

  // Pagination
  const [page, setPage] = useState(1);
  const show = 8;
  const end = useMemo(() => show * page, [page]);
  const start = useMemo(() => end - show, [end]);
  const pages = useMemo(
    () => Math.ceil(entries.length / show),
    [entries.length]
  );
  const filteredEntries = useMemo(
    () => entries.slice(start, end),
    [end, entries, start]
  );

  // Animation
  const [isVisible, setIsVisible] = useState(false);
  const animations = [
    {
      fadeIn: useSpring({
        opacity: isVisible ? 1 : 0,
      }),
    },
  ];

  return (
    <VisibilitySensor
      onChange={setIsVisible}
      active={!isVisible}
      partialVisibility
      minTopValue="200"
    >
      <animated.div
        className="latest-entries space-bigger"
        style={animations[0].fadeIn}
      >
        <h3>Latest Entries</h3>

        {filteredEntries.map((entry, i) => (
          <BlogThumbnail entry={entry} key={i} />
        ))}

        <div className="space-bigger">
          <Pagination
            total={pages}
            current={page}
            onClick={(page) => setPage(page)}
          />
        </div>
      </animated.div>
    </VisibilitySensor>
  );
};

const mapStateToProps = (state) => ({
  blog: state.blog,
});

export default connect(mapStateToProps)(LatestEntries);
