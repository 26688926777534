import "./Pagination.scss";

import React from "react";
import { times } from "lodash";

const Pagination = ({ total, current, onClick }) => {
  return (
    <ul className="pagination">
      {times(total, (i) => (
        <li className={i + 1 === current ? "active" : null} key={i}>
          <button type="button" onClick={() => onClick(i + 1)}>
            {i + 1}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default Pagination;
