import "./ProductThumbnail.scss";

import React from "react";
import bahtIcon from "assets/icon/baht.svg";

const ProductThumbnail = ({ product }) => {
  return (
    <div className="product-thumbnail">
      <div className="product-thumbnail-image space-smaller">
        <div className="product-thumbnail-image-inner">
          <img src={product.img} alt={product.name} />
        </div>
      </div>
      <div className="product-thumbnail-text space">
        <img className="icon" src={product.icon} alt={product.name + " icon"} />
        <h5>{product.name}</h5>
        <div className="product-thumbnail-description space-smaller">
          <p className="text-muted">{product.description}</p>
        </div>
        <div className="text-smaller">
          <img src={bahtIcon} alt="Baht" /> {product.price}
        </div>
      </div>
    </div>
  );
};

export default ProductThumbnail;
