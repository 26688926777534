import "./BlogThumbnail.scss";

import { Col, Row } from "reactstrap";
import React, { useState } from "react";
import { animated, useSpring } from "react-spring";

import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";

const BlogThumbnail = ({ entry }) => {
  // Animation
  const [isVisible, setIsVisible] = useState(false);
  const animations = [
    {
      fadeInLeft: useSpring({
        position: "relative",
        left: isVisible ? "0" : "30px",
        opacity: isVisible ? 1 : 0,
      }),
    },
  ];

  return (
    <VisibilitySensor
      onChange={setIsVisible}
      active={!isVisible}
      partialVisibility
      minTopValue="100"
    >
      <animated.div style={animations[0].fadeInLeft}>
        <div className="blog-thumbnail space">
          <Row>
            <Col md="5">
              <div
                className="blog-thumbnail-image background-image"
                style={{
                  backgroundImage: "url(" + entry.thumbnail + ")",
                }}
              ></div>
            </Col>
            <Col md="7">
              <div className="blog-thumbnail-text space">
                <div className="text-uppercase text-smaller text-muted">
                  {entry.category || "Uncategorized"}
                </div>
                <h5 className="space-smaller">{entry.title}</h5>
                <p className="text-muted">
                  {entry.content && entry.content.length > 190
                    ? entry.content.substring(0, 190) + "..."
                    : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt…"}
                </p>
                <span className="text-muted text-smaller">{entry.date}</span>
                <Link
                  to={"/blog/" + entry.slug}
                  className="btn btn-left btn-inline-block text-smaller"
                >
                  Read more
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </animated.div>
    </VisibilitySensor>
  );
};

export default BlogThumbnail;
