import "./ZodiacScrollbar.scss";

import { animated, useSpring } from "react-spring";

import React from "react";
import { connect } from "react-redux";
import { updateZodiac } from "../../redux/actions/shopActions";
import { withRouter } from "react-router-dom";

const ZodiacScrollbar = ({
  zodiacs,
  currentZodiac,
  updateZodiac,
  ...props
}) => {
  // Animation
  const animations = [
    {
      fadeInLeft: useSpring({
        position: "relative",
        left: "0",
        opacity: 1,
        from: {
          left: "30px",
          opacity: 0,
        },
      }),
    },
  ];

  return (
    <animated.div style={animations[0].fadeInLeft}>
      <div className="zodiac-scrollbar-container">
        <ul className="zodiac-scrollbar">
          {zodiacs.map((zodiac, i) => (
            <li key={i}>
              <button
                type="button"
                className={currentZodiac === i ? "active" : null}
                onClick={() => {
                  props.history.push(`/shop/${zodiac.name.toLowerCase()}`);
                }}
              >
                {zodiac.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </animated.div>
  );
};

export default connect(
  (state) => ({
    zodiacs: state.shop.zodiacs,
    currentZodiac: state.shop.currentZodiac,
  }),
  { updateZodiac }
)(withRouter(ZodiacScrollbar));
