import blogReducer from './blogReducer';
import { combineReducers } from 'redux';
import errorReducer from './errorReducer';
import shopReducer from './shopReducer';

export default combineReducers({
  error: errorReducer,
  shop: shopReducer,
  blog: blogReducer,
});
