import React, { useState } from "react";
import { animated, useSpring, useTrail } from "react-spring";

import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import { connect } from "react-redux";
import { useMemo } from "react";

const RelatedPosts = ({ blog }) => {
  const { entries } = blog;
  const sortedEntries = useMemo(
    () => [...entries].sort((a, b) => (a.views < b.views ? 1 : -1)).slice(0, 3),
    [entries]
  );

  // Animation
  const [isVisible, setIsVisible] = useState(false);
  const animations = [
    {
      fadeIn: useSpring({
        opacity: isVisible ? 1 : 0,
      }),
      fadeLeftTrail: useTrail(sortedEntries.length, {
        position: "relative",
        left: isVisible ? "0" : "30px",
        opacity: isVisible ? 1 : 0,
      }),
    },
  ];

  return (
    <VisibilitySensor
      onChange={setIsVisible}
      active={!isVisible}
      partialVisibility
      minTopValue="200"
    >
      <animated.div style={animations[0].fadeIn}>
        <h3>Related Posts</h3>
        {sortedEntries.map((entry, i) => (
          <animated.div
            className="space"
            style={animations[0].fadeLeftTrail[i]}
            key={i}
          >
            <div className="text-uppercase text-smaller">{entry.category}</div>
            <h5 className="space-smaller">{entry.title}</h5>
            <Link to={"/blog/" + entry.slug} className="btn btn-left">
              Read more
            </Link>
          </animated.div>
        ))}
      </animated.div>
    </VisibilitySensor>
  );
};

const mapStateToProps = (state) => ({
  blog: state.blog,
});

export default connect(mapStateToProps)(RelatedPosts);
