import "./Blog.scss";

import { Col, Container, Row } from "reactstrap";

import Ads from "components/Ads";
import FeaturedBlogEntry from "components/FeaturedBlogEntry";
import Instagram from "components/Instagram";
import LatestEntries from "components/LatestEntries";
import PopularTopics from "components/PopularTopics";
import React from "react";
import RelatedTopics from "components/RelatedTopics";
import SocialMedia from "components/SocialMedia";
import { connect } from "react-redux";
import { fetch as fetchBlog } from "../../redux/actions/blogActions";
import { useEffect } from "react";

const Blog = ({ blog, fetchBlog }) => {
  // Fetch entries
  useEffect(() => {
    if (!blog.entries.length) {
      fetchBlog();
    }
  }, [blog.entries.length, fetchBlog]);

  return (
    <>
      <div className="blog-section">
        <Container>
          <Row>
            <Col lg="8">
              <FeaturedBlogEntry />
              <LatestEntries />
            </Col>
            <Col lg={{ size: 3, offset: 1 }}>
              <PopularTopics />
              <RelatedTopics />
              <Ads />
              <Instagram />
              <SocialMedia />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    blog: state.blog,
  }),
  {
    fetchBlog,
  }
)(Blog);
