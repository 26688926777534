import Ig1Image from 'assets/img/instagram/alex-rodriguez-santibanez-TS6Ml8mSXQU-unsplash.jpg';
import Ig2Image from 'assets/img/instagram/amy-shamblen-pJ_DCj9KswI-unsplash.jpg';
import Ig3Image from 'assets/img/instagram/beasty-0BcxmQx9xas-unsplash.jpg';
import Ig4Image from 'assets/img/instagram/brooke-cagle-uAfjZu--U2g-unsplash.jpg';
import Ig5Image from 'assets/img/instagram/jason-leung-6rGJQry0_WI-unsplash.jpg';
import Ig6Image from 'assets/img/instagram/martin-hoffmann-9LkmOjkVEgM-unsplash.jpg';
import Ig7Image from 'assets/img/instagram/mike-dorner-sf_1ZDA1YFw-unsplash.jpg';
import Ig8Image from 'assets/img/instagram/natalia-y-DIewyzpUbRc-unsplash.jpg';
import Ig9Image from 'assets/img/instagram/sagar-rana-bFDdb5Yl_-w-unsplash.jpg';

export const images = [
  Ig1Image,
  Ig2Image,
  Ig3Image,
  Ig4Image,
  Ig5Image,
  Ig6Image,
  Ig7Image,
  Ig8Image,
  Ig9Image,
];
