import React, { useState } from 'react';
import { animated, useSpring, useTrail } from 'react-spring';

import VisibilitySensor from 'react-visibility-sensor';
import { links } from 'samples/social-media';

const SharePost = () => {
  // Animation
  const [ isVisible, setIsVisible ] = useState(false);
  const animations = [
    {
      fadeIn: useSpring({
        opacity: isVisible ? 1 : 0,
      }),
      fadeUpTrail: useTrail(links.length, {
        position: 'relative',
        top: isVisible ? '0' : '30px',
        opacity: isVisible ? 1 : 0,
      })
    }
  ];

  return (
    <VisibilitySensor onChange={ setIsVisible } active={ !isVisible } partialVisibility minTopValue="200">
      <animated.div style={ animations[0].fadeIn }>
        <h3>
          Share Post
        </h3>
        <ul className="list-unstyled space">
          { links.map((link, i) =>
            <animated.li className="space-smaller" key={ i } style={ animations[0].fadeUpTrail[i] }>
              <a href={ link.href } target="_blank" rel="noopener noreferrer" className="link-gray">
                { link.name }
              </a>
            </animated.li>
          ) }
        </ul>
      </animated.div>
    </VisibilitySensor>
  );
}

export default SharePost;
