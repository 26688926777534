export const links = [
  {
    name: 'Facebook',
    href: 'https://www.facebook.com/sugarandpep/'
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/sugar.pep/'
  },
  {
    name: 'Twitter',
    href: 'https://twitter.com/hashtag/sugarpep'
  },
];
