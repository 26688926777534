import { Col, Container, Row } from 'reactstrap';

import AboutSlide from 'components/AboutSlide';
import BannerImage1x from 'assets/img/about/nathan-n-b7Rai2d_mg8-unsplash.jpg';
import Image1 from 'assets/img/about/sergio-souza-6X5kALV76EI-unsplash.jpg';
import Image2 from 'assets/img/about/natasha-kasim-KsmXcL_dnzM-unsplash.jpg';
import Image3 from 'assets/img/about/greg-kantra-23w5guoXxMM-unsplash.jpg';
import Image4 from 'assets/img/about/karina-carvalho-ng-hA8XCIBQ-unsplash.jpg';
import React from 'react';

const About = () => {
  return (
    <>
      <AboutSlide />
      <div className="page-margin">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <div className="text-center">
                <h3>
                  Sugar & Pepper is the love for innovations and styles. We are the new generation of cosmetic.
                </h3>
                <a href='/' className="btn space-bigger">
                  Read more
                </a>
              </div>
            </Col>
            <Col md="5">
              <div className="background-image landscape vertical-center-on-tablet space" style={{
                backgroundImage: 'url(' + Image1 + ')'
              }}></div>
            </Col>
            <Col md={{ size: 5, offset: 2 }}>
              <div className="background-image portrait space" style={{
                backgroundImage: 'url(' + Image2 + ')'
              }}></div>
            </Col>
          </Row>
        </Container>
        <div className="space-bigger">
          <div className="background-image panorama" style={{
            backgroundImage: 'url(' + BannerImage1x + ')'
          }}></div>
          <div className="boxes">
            <div className="box">
              <div className="box-inner">
                <p className="text-muted">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris…
                </p>
                <a href='/' className="btn btn-left space">
                  Read more
                </a>
              </div>
            </div>
            <div className="box background-image" style={{
              backgroundImage: 'url(' + Image3 + ')'
            }}></div>
          </div>
        </div>
        <Container>
          <Row>
            <Col md="5">
              <div className="background-image portrait space" style={{
                backgroundImage: 'url(' + Image4 + ')'
              }}></div>
            </Col>
            <Col md={{ size: 5, offset: 2 }}>
              <div className="center-on-tablet space">
                <h3>
                  Learn fashion and perfume matching.
                </h3>
                <a href='/' className="btn btn-left space">
                  Read more
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default About;