import './Header.scss';

import { animated, useSpring } from 'react-spring';

import React from 'react';

const Header = ({
  image,
  title,
  subtitle,
  children,
}) => {
  // Animation
  const animations = [
    {
      fadeIn: useSpring({
        opacity: 1,
        from: {
          opacity: 0
        }
      }),
      slideUp: useSpring({
        position: 'relative',
        top: '0',
        from: {
          top: '20px',
        },
      }),
      slideRight: useSpring({
        position: 'relative',
        right: '0',
        from: {
          right: '20px',
        },
      }),
      slideDown: useSpring({
        position: 'relative',
        bottom: '0',
        from: {
          bottom: '20px',
        },
      })
    }
  ];

  return (
    <div className="header">
      <animated.div className="header-image background-image full-cover" style={{ ...animations[0].fadeIn, backgroundImage: 'url(' + image + ')' }}></animated.div>
      
      <div className="header-text full-cover">
        <div className="header-text-inner">
          <animated.h1 style={{ ...animations[0].fadeIn, ...animations[0].slideUp }}>
            {title}
          </animated.h1>
          <animated.p style={{ ...animations[0].fadeIn, ...animations[0].slideRight }}>
            {subtitle}
          </animated.p>
          {!children ? null :
            <animated.div style={{ ...animations[0].fadeIn, ...animations[0].slideDown }}>
              {children}
            </animated.div>
          }
        </div>
      </div>
    </div>
  );
}

export default Header;
