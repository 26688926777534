import "./ZodiacProfile.scss";

import { Col, Row } from "reactstrap";
import React, { useState } from "react";
import { animated, useSpring, useTrail } from "react-spring";

import VisibilitySensor from "react-visibility-sensor";
import { connect } from "react-redux";

const ZodiacProfile = ({ zodiac }) => {
  // Animation
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const animations = [
    {
      fadeIn: useSpring({
        opacity: isVisible1 ? 1 : 0,
      }),
      slideUp: useSpring({
        position: "relative",
        top: isVisible1 ? "0" : "20px",
      }),
    },
    {
      fadeLeft: useSpring({
        position: "relative",
        left: isVisible2 ? "0" : "20px",
        opacity: isVisible2 ? 1 : 0,
      }),
    },
    {
      fadeInUpTrail: useTrail(zodiac.features.length, {
        position: "relative",
        top: isVisible3 ? "0" : "50px",
        opacity: isVisible3 ? 1 : 0,
      }),
    },
  ];

  return (
    <div className="zodiac-profile-container">
      <VisibilitySensor
        onChange={setIsVisible1}
        partialVisibility
        minTopValue="200"
      >
        <animated.div style={animations[0].fadeIn}>
          <Row>
            <Col md="6" lg={{ size: 5, offset: 1 }}>
              <div className="zodiac-profile-image">
                <img src={zodiac.img} alt={zodiac.name + " model"} />
              </div>
            </Col>
            <Col md="6" lg={{ size: 4, offset: 1 }}>
              <div className="zodiac-profile-description">
                <div className="zodiac-profile-description-inner">
                  <animated.div className="space" style={animations[0].slideUp}>
                    <h2>{zodiac.title}</h2>
                    <p className="space">{zodiac.description}</p>
                    <button type="button" className="btn btn-left">
                      Discover
                    </button>
                  </animated.div>
                </div>
              </div>
            </Col>
          </Row>
        </animated.div>
      </VisibilitySensor>
      <Row className="space">
        <Col lg={{ size: 4, offset: 1 }}>
          <VisibilitySensor
            onChange={setIsVisible2}
            partialVisibility
            minTopValue="300"
          >
            <div className="space">
              <animated.h2 style={animations[1].fadeLeft}>
                {zodiac.summary}
              </animated.h2>
            </div>
          </VisibilitySensor>
        </Col>
        <Col lg={{ size: 6, offset: 1 }}>
          <VisibilitySensor
            onChange={setIsVisible3}
            partialVisibility
            minTopValue="300"
          >
            <div className="space">
              {zodiac.features.map((feature, i) => (
                <animated.div style={animations[2].fadeInUpTrail[i]} key={i}>
                  <Row className="zodiac-profile-feature">
                    <Col xs="4">
                      <h4>{feature.title}</h4>
                    </Col>
                    <Col xs="8">
                      <p>{feature.description}</p>
                    </Col>
                  </Row>
                </animated.div>
              ))}
            </div>
          </VisibilitySensor>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  zodiac: state.shop.zodiacs[state.shop.currentZodiac],
});

export default connect(mapStateToProps)(ZodiacProfile);
