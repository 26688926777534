import "./FeaturedProduct.scss";

import { Col, Row } from "reactstrap";
import React, { useState } from "react";
import { animated, useSpring } from "react-spring";

import VisibilitySensor from "react-visibility-sensor";
import { connect } from "react-redux";
import { useMemo } from "react";

const FeaturedProduct = (props) => {
  const { zodiacs } = props;
  const featuredZodiac = useMemo(
    () => zodiacs.filter((zodiac) => zodiac.product.featured)[0],
    [zodiacs]
  );
  const { product } = featuredZodiac;

  // Animation
  const [isVisible, setIsVisible] = useState(false);
  const animations = [
    {
      fadeIn: useSpring({
        opacity: isVisible ? 1 : 0,
      }),
      slideLeft: useSpring({
        position: "relative",
        left: isVisible ? "0" : "20px",
      }),
    },
  ];

  return (
    <VisibilitySensor
      onChange={setIsVisible}
      partialVisibility
      minTopValue="500"
    >
      <animated.div style={animations[0].fadeIn}>
        <Row className="featured space">
          <Col md="7">
            <div
              className="background-image square contain space"
              style={{
                backgroundImage: "url(" + product.featured.img + ")",
              }}
              data-rjs={product.featured.img}
            ></div>
          </Col>
          <Col md={{ size: 4, offset: 1 }}>
            <div className="featured-text">
              <div className="featured-text-inner">
                <animated.div style={animations[0].slideLeft}>
                  <img
                    src={featuredZodiac.icon}
                    alt={featuredZodiac.name + " icon"}
                    className="icon"
                  />
                  <h3 className="inline">{product.featured.title}</h3>
                  <p className="space">{product.featured.description}</p>
                  <a
                    href={"/shop/" + featuredZodiac.name.toLowerCase()}
                    className="btn"
                  >
                    Discover
                  </a>
                </animated.div>
              </div>
            </div>
          </Col>
        </Row>
      </animated.div>
    </VisibilitySensor>
  );
};

const mapStateToProps = (state) => ({
  zodiacs: state.shop.zodiacs,
});

export default connect(mapStateToProps)(FeaturedProduct);
