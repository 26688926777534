import React, { useState } from 'react';
import { animated, useSpring, useTrail } from 'react-spring';

import VisibilitySensor from 'react-visibility-sensor';

const RelatedTopics = () => {
  const topics = [
    'Oil Perfume',
    'Horoscope',
    'Perfume Industry',
    'Fashion',
    'Jewelry'
  ];

  // Animation
  const [ isVisible, setIsVisible ] = useState(false);
  const animations = [
    {
      fadeIn: useSpring({
        opacity: isVisible ? 1 : 0,
      }),
      fadeUpTrail: useTrail(topics.length, {
        position: 'relative',
        top: isVisible ? '0' : '30px',
        opacity: isVisible ? 1 : 0,
      })
    }
  ];

  return (
    <VisibilitySensor onChange={ setIsVisible } active={ !isVisible } partialVisibility minTopValue="100">
      <animated.div className="space-bigger" style={ animations[0].fadeIn }>
        <h3>
          Related Topics
        </h3>
        <ul className="list-unstyled space">
          { topics.map((topic, i) =>
            <animated.li className="space-smaller" key={ i } style={ animations[0].fadeUpTrail[i] }>
              <a href="/blog" className="link-gray">{ topic }</a>
            </animated.li>
          ) }
        </ul>
      </animated.div>
    </VisibilitySensor>
  );
}

export default RelatedTopics;
