import "./Entry.scss";

import { Col, Row } from "reactstrap";
import React, { useState } from "react";
import { animated, useTrail } from "react-spring";

import { Container } from "reactstrap";
import ReactMarkdown from "react-markdown";
import RelatedPosts from "components/RelatedPosts";
import SharePost from "components/SharePost";
import UserBadge from "components/UserBadge";
import VisibilitySensor from "react-visibility-sensor";
import { connect } from "react-redux";
import { fetch as fetchBlog } from "../../redux/actions/blogActions";
import { useEffect } from "react";
import { useMemo } from "react";

const findEntry = (entries, slug) => {
  if (entries.length) {
    // Filter entries
    const filteredEntries = entries.filter((entry) => {
      return entry.slug === slug;
    });

    if (filteredEntries.length) {
      return filteredEntries[0];
    }
  }

  return false;
};

const Entry = ({ fetchBlog, ...props }) => {
  const { blog } = props;
  const { entries } = blog;
  const entry = useMemo(
    () => findEntry(entries, props.match.params.slug),
    [entries, props.match.params.slug]
  );

  // Fetch entries
  useEffect(() => {
    if (!blog.entries.length) {
      fetchBlog();
    }
  }, [blog.entries.length, fetchBlog]);

  // Animation
  const [isVisible, setIsVisible] = useState(false);
  const animations = [
    {
      fadeUpTrail: useTrail(4, {
        position: "relative",
        top: isVisible ? "0" : "30px",
        opacity: isVisible ? 1 : 0,
      }),
    },
  ];

  if (entry) {
    const { title, category, date, author, summary, content, thumbnail } =
      entry;

    return (
      <VisibilitySensor
        onChange={setIsVisible}
        partialVisibility
        minTopValue="200"
      >
        <Container>
          <div className="entry page-margin">
            <Row className="justify-content-center space">
              <Col md="8">
                <div className="text-center space-bigger">
                  <animated.h1 style={animations[0].fadeUpTrail[0]}>
                    {title}
                  </animated.h1>
                  <animated.div style={animations[0].fadeUpTrail[1]}>
                    <UserBadge user={author} />
                  </animated.div>
                </div>
              </Col>
              <Col md="8">
                <animated.div style={animations[0].fadeUpTrail[2]}>
                  <h5 className="inline-block text-uppercase">{category}</h5>
                  <div className="entry-date inline-block text-muted">
                    Published {date}
                  </div>
                </animated.div>
              </Col>
            </Row>
            <animated.div style={animations[0].fadeUpTrail[3]}>
              <Row className="justify-content-center space">
                <Col md={{ size: 12 }}>
                  <Row className="justify-content-center">
                    <Col md={{ size: 8 }}>
                      <ReactMarkdown children={summary} />
                    </Col>
                  </Row>
                </Col>
                <Col md={{ size: 12 }}>
                  <div className="space">
                    <div
                      className="background-image"
                      style={{
                        backgroundImage: "url(" + thumbnail + ")",
                        paddingTop: "62.5%",
                      }}
                    ></div>
                  </div>
                </Col>
                <Col md={{ size: 4 }}>
                  <div className="space">
                    <SharePost />
                  </div>
                  <div className="space-bigger">
                    <RelatedPosts />
                  </div>
                </Col>
                <Col md={{ size: 6 }}>
                  <div className="space">
                    <ReactMarkdown children={content} />
                  </div>
                </Col>
              </Row>
            </animated.div>
          </div>
        </Container>
      </VisibilitySensor>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state) => ({
  blog: state.blog,
});

export default connect(mapStateToProps, { fetchBlog })(Entry);
