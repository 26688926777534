import "./VerticalBlogThumbnail.scss";

import React from "react";

const VerticalBlogThumbnail = ({ entry }) => {
  return (
    <a href={"/blog/" + entry.slug}>
      <div className="vertical-blog-thumbnail">
        <div
          className="background-image portrait"
          style={{
            backgroundImage: "url(" + entry.thumbnail + ")",
          }}
        ></div>
        <div className="vertical-blog-thumbnail-text">
          <div className="space">
            <h5>{entry.title}</h5>
            <p className="text-muted">
              {entry.content
                ? entry.content.substring(0, 70) + "..."
                : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo."}
            </p>
          </div>
        </div>
      </div>
    </a>
  );
};

export default VerticalBlogThumbnail;
