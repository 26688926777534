import { Col, Row } from 'reactstrap';
import React, { useState } from 'react';
import { animated, useTrail } from 'react-spring';

import VerticalBlogThumbnail from 'components/VerticalBlogThumbnail';
import VisibilitySensor from 'react-visibility-sensor';

const BlogShowcase = ({ entries, heading }) => {
  // Animation
  const [ isVisible, setIsVisible ] = useState(false);
  const animations = [
    {
      fadeInUpTrail: useTrail(4, {
        position: 'relative',
        top: isVisible ? '0' : '20px',
        opacity: isVisible ? 1 : 0,
      })
    }
  ];

  return (
    <VisibilitySensor onChange={ setIsVisible } partialVisibility minTopValue="400">
      <div className="space">
        <animated.h2 className="text-center" style={ animations[0].fadeInUpTrail[0] }>
          { heading }
        </animated.h2>
        <div className="blog-showcase space-bigger text-center">
          <Row>
            { entries.slice(0, 3).map((entry, i) =>
              <Col md="4" className={ (i === 1) ? 'space' : null } key={ i }>
                <animated.div style={ animations[0].fadeInUpTrail[i + 1] }>
                  <VerticalBlogThumbnail entry={ entry } />
                </animated.div>
              </Col>
            ) }
          </Row>
        </div>
      </div>
    </VisibilitySensor>
  );
}

export default BlogShowcase;
