import './Footer.scss';

import { Col, Container, Row } from 'reactstrap';
import { faFacebookSquare, faInstagram, faLine, faTwitter } from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogoBadgeBlack from 'assets/logo/badge-black.svg';
import LogoText from 'assets/logo/text.svg';
import NewsletterForm from 'components/NewsletterForm';
import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="text-center">
        <a className="logo" href="/">
          <img src={ LogoBadgeBlack } alt="Sugarpep Logo Badge" />
          <br/>
          <img src={ LogoText } alt="Sugarpep Logo Text" />
        </a>
      </div>
      <Container>
        <Row className="footer-text">
          <Col lg={{ size: 4, offset: 1 }}>
            <div className="footer-newsletter">
              <NewsletterForm />
            </div>
          </Col>
          <Col lg="2" xl={{ offset: 1 }}>
            <ul className="footer-nav list-unstyled">
              <li><a href="/career" className="link-gray">Jobs & Careers</a></li>
              <li><a href="/about" className="link-gray">About Us</a></li>
              <li><a href="/contact" className="link-gray">Contact Us</a></li>
              <li><a href="/stores" className="link-gray">Store Location</a></li>
            </ul>
          </Col>
          <Col lg="3" xl="2">
            <ul className="footer-nav list-unstyled">
              <li><a href="/press" className="link-gray">Press</a></li>
              <li><a href="/return-policy" className="link-gray">Return Policy</a></li>
              <li><a href="/terms" className="link-gray">Terms & Conditions</a></li>
              <li><a href="/privacy" className="link-gray">Privacy Policy</a></li>
            </ul>
          </Col>
          <Col lg="2">
            <ul className="footer-social list-unstyled">
              <li>
                <a href="/" target="_blank" rel="noopener noreferrer" className="link-gray">
                  <FontAwesomeIcon icon={ faLine } />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/sugar.pep/" target="_blank" rel="noopener noreferrer" className="link-gray">
                  <FontAwesomeIcon icon={ faInstagram } />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/hashtag/sugarpep" target="_blank" rel="noopener noreferrer" className="link-gray">
                  <FontAwesomeIcon icon={ faTwitter } />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/sugarandpep/" target="_blank" rel="noopener noreferrer" className="link-gray">
                  <FontAwesomeIcon icon={ faFacebookSquare } />
                </a>
              </li>
            </ul>
          </Col>
          <Col xs="10" lg={{ offset: 1 }}>
            <div className="footer-copyright text-muted">
              © 2020 Sugar & Pepper
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
