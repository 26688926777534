import React, { useState } from "react";

import BlogShowcase from "components/BlogShowcase";
import { connect } from "react-redux";
import { fetch } from "../../redux/actions/blogActions";
import { useEffect } from "react";

const ZodiacBlogShowcase = (props) => {
  const { blog, zodiac } = props;
  const { entries } = blog;
  const [filteredEntries, setFilteredEntries] = useState([]);

  useEffect(() => {
    if (entries.length && !filteredEntries.length) {
      const filteredEntries = entries.filter((entry) =>
        entry.title.includes(zodiac.name)
      );

      if (filteredEntries.length) {
        setFilteredEntries(filteredEntries);
        return;
      }

      setFilteredEntries(entries);
      return;
    }
  }, [entries, filteredEntries.length, zodiac.name]);

  return (
    <BlogShowcase
      entries={filteredEntries}
      heading={[
        "More On ",
        <span className="text-italic text-underlined" key="0">
          {zodiac.name}
        </span>,
      ]}
    />
  );
};

const mapStateToProps = (state) => ({
  blog: state.blog,
  zodiac: state.shop.zodiacs[state.shop.currentZodiac],
});

export default connect(mapStateToProps, { fetch })(ZodiacBlogShowcase);
