import AquariusIcon from "assets/icon/zodiacs/Aquarius.svg";
import AquariusModel from "assets/img/models/Aquarius@2x.jpg";
import AquariusProduct from "assets/img/products/Aquarius@2x.png";
import AriesIcon from "assets/icon/zodiacs/Aries.svg";
import AriesModel from "assets/img/models/Aries@2x.jpg";
import AriesProduct from "assets/img/products/Aries@2x.png";
import CancerIcon from "assets/icon/zodiacs/Cancer.svg";
import CancerModel from "assets/img/models/Cancer@2x.jpg";
import CancerProduct from "assets/img/products/Cancer@2x.png";
import CapricornIcon from "assets/icon/zodiacs/Capricorn.svg";
import CapricornModel from "assets/img/models/Capricorn@2x.jpg";
import CapricornProduct from "assets/img/products/Capricorn@2x.png";
import GeminiIcon from "assets/icon/zodiacs/Gemini.svg";
import GeminiModel from "assets/img/models/Gemini@2x.jpg";
import GeminiProduct from "assets/img/products/Gemini@2x.png";
import LeoIcon from "assets/icon/zodiacs/Leo.svg";
import LeoModel from "assets/img/models/Leo@2x.jpg";
import LeoProduct from "assets/img/products/Leo@2x.png";
import LibraFeatured from "assets/img/featured/Libra@2x.png";
import LibraIcon from "assets/icon/zodiacs/Libra.svg";
import LibraModel from "assets/img/models/Libra@2x.jpg";
import LibraProduct from "assets/img/products/Libra@2x.png";
import PiscesIcon from "assets/icon/zodiacs/Pisces.svg";
import PiscesModel from "assets/img/models/Pisces@2x.jpg";
import PiscesProduct from "assets/img/products/Pisces@2x.png";
import SagittariusIcon from "assets/icon/zodiacs/Sagittarius.svg";
import SagittariusModel from "assets/img/models/Sagittarius@2x.jpg";
import SagittariusProduct from "assets/img/products/Sagittarius@2x.png";
import ScorpioIcon from "assets/icon/zodiacs/Scorpio.svg";
import ScorpioModel from "assets/img/models/Scorpio@2x.jpg";
import ScorpioProduct from "assets/img/products/Scorpio@2x.png";
import TaurusIcon from "assets/icon/zodiacs/Taurus.svg";
import TaurusModel from "assets/img/models/Taurus@2x.jpg";
import TaurusProduct from "assets/img/products/Taurus@2x.png";
import VirgoIcon from "assets/icon/zodiacs/Virgo.svg";
import VirgoModel from "assets/img/models/Virgo@2x.jpg";
import VirgoProduct from "assets/img/products/Virgo@2x.png";

export const zodiacs = [
  {
    name: "Leo",
    icon: LeoIcon,
    img: LeoModel,
    title: "A lion’s bravery is in every Leo.",
    description:
      "This is a lion — fearless and impossible to challenge, hurt or destroy, their only weaknesses being fear and aggression towards those they confront. Living in a cave, a Lion always needs to have one, nesting and finding comfort in hard times. However, they should never stay there for long. With their head high, they have to face others with dignity and respect, never raising a voice, a hand, or a weapon, bravely walking through the forest they rule.",
    summary:
      "A Leo is creative, passionate, generous, warmhearted, cheerful, and humorous.",
    features: [
      {
        title: "Natural Leader",
        description:
          "People born under the sign of Leo are natural born leaders. They are dramatic, creative, self-confident, dominant and extremely difficult to resist, able to achieve anything they want to in any area of life they commit to. There is a specific strength to a Leo and their “king of the jungle” status.",
      },
      {
        title: "Friendly",
        description:
          "Leo often has many friends for they are generous and loyal. Self-confident and attractive, this is a Sun sign capable of uniting different groups of people and leading them as one towards a shared cause, and their healthy sense of humor makes collaboration with other people even easier.",
      },
      {
        title: "Warmhearted",
        description:
          "Leo belongs to the element of Fire, just like Aries and Sagittarius. This makes them warmhearted, in love with life, trying to laugh and have a good time. Able to use their mind to solve even the most difficult problems, they will easily take initiative in resolving various complicated situations.",
      },
    ],
    product: {
      img: LeoProduct,
      description:
        "A scent that will remind you of summers. Be fun and exciting like a Leo.",
      price: 395,
      bestSeller: true,
    },
    horoscope: {
      weekly: {
        love:
          "When the sun enters your sign at the start of the week, it’s a powerful combo. You have enough confidence now to do pretty much anything you put your mind to. A lucky Mars-Jupiter trine at week’s end puts a fortunate spin on your dates over the weekend, and getting those lucky breaks can be impressive. You don’t have to let your date in on the fact that it’s more luck than effort!",
        life:
          "Pace yourself. When it comes to exercise, you might need to do more. Perhaps you love good food but don't care for the thought of working out. You don't mind the pressure and stress at work because these are about security. Work stress combined with too little exercise and too many gourmet meals creates a recipe for a health disaster. Delegate some work, eat more healthfully, and learn to love exercise.",
        work:
          "The energy now can bring powerful feelings to the surface. The cosmos makes you want to be at home or make your workplace homier. This is a lucky time for food, shelter, and any business concerned with domestic affairs. People may want to have fun instead of work. This is a wonderful time to dress your best and make a dramatic presentation.",
      },
    },
  },
  {
    name: "Virgo",
    icon: VirgoIcon,
    img: VirgoModel,
    title: "A thoughtful soul lives in every Virgo.",
    description:
      "Virgo – the Disappointed Goddess Seeking goodness in humankind is the story of Virgo, and disappointment seems to be inevitable from their point of view. The first time they came from their cloud and jumped onto planet Earth, it felt like their mission is to use their existence for good, discovering ways of justice and purity in other people. Once they fail to find it too many times, Virgos will pull away, get lost, turn to substance abuse, or simply separate from other people to sit on the bench, criticize and judge.",
    summary: "A Virgo is loyal, analytical, kind, hardworking, and practical.",
    features: [
      {
        title: "Perfectionist",
        description:
          "Virgos are always paying attention to the smallest details and their deep sense of humanity makes them one of the most careful signs of the zodiac. Their methodical approach to life ensures that nothing is left to chance, and although they are often tender, their heart might be closed for the outer world.",
      },
      {
        title: "Communicative",
        description:
          "Since Mercury is the ruling planet of this sign, its representatives have a well-developed sense of speech and writing, as well as all other forms of communication. Many Virgos may choose to pursue a career as writers, journalists, and typists, but their need to serve others makes them feel good as caregivers, on a clear mission to help.",
      },
      {
        title: "Practical",
        description:
          "Virgo is an Earth sign, fitting perfectly between Taurus and Capricorn. This will lead to a strong character, but one that prefers conservative, well-organized things and a lot of practicality in their everyday life. These individuals have an organized life, and even when they let go to chaos, their goals and dreams still have strictly defined borders in their mind. Constantly worried that they missed a detail that will be impossible to fix, they can get stuck in details, becoming overly critical and concerned about matters that nobody else seems to care much about.",
      },
    ],
    product: {
      img: VirgoProduct,
      description:
        "Simple, clean and romantic. A Virgo is simply a perfectionist.",
      price: 395,
      bestSeller: true,
    },
    horoscope: {
      weekly: {
        love:
          "Have you been wanting to tell someone how you really feel? The midweek Mercury-Venus conjunction in sensitive Cancer is an ideal time to express yourself. Loving Venus spends time with dramatic Leo over the weekend, which could be uncomfortable for you at times. You aren’t usually an attention seeker, but what’s the worst that could happen? Live in the moment.",
        life:
          "Your enthusiasm for good health and a fit body is about to reach a new level. This isn't just about feeling great. It's also about looking fantastic and feeling great about yourself and your life. Whatever you're doing, don't burn yourself out. You may want to take it to the limit, but spare a thought for your body.",
        work:
          "You will be happiest in any planning capacity where you can use your imagination and help others feel more appreciated. The energy is exciting now. You can make a positive impression on customers or authorities. Get up and be as active as possible. Things settle down later. It will be extra important to follow the rules. This is a great time to plan or introduce a recycling program at work.",
      },
    },
  },
  {
    name: "Libra",
    icon: LibraIcon,
    img: LibraModel,
    title: "Every Libra just wants peace.",
    description:
      "There is something awfully insecure about Libra, as if they were unsure which plate to burden next, aware that things pass and teach us to be careful around other people. Whatever we do in our lifetimes, only serves to point the way for our Souls towards that “higher power” to finally measure our existence. Telling us where we went wrong or what we did right, Libras unconsciously teach us that true liberation hides in lightness.",
    summary:
      "A Libra is cooperative, diplomatic, gracious, fair-minded, and social.",
    features: [
      {
        title: "Peaceful",
        description:
          "People born under the sign of Libra are peaceful, fair, and they hate being alone. Partnership is very important for them, as their mirror and someone giving them the ability to be the mirror themselves. These individuals are fascinated by balance and symmetry, they are in a constant chase for justice and equality, realizing through life that the only thing that should be truly important to themselves in their own inner core of personality.",
      },
      {
        title: "Great Lovers",
        description:
          "Planet ruling the sign of Libra is Venus, making these people great lovers but also fond of expensive, material things. Their lives need to be enriched by music, art, and beautiful places they get a chance to visit.",
      },
      {
        title: "Intellectual",
        description:
          "The sign of Libra is an Air sign, set between Gemini and Aquarius, giving these individuals constant mental stimuli, strong intellect and a keen mind. They will be inspired by good books, insurmountable discussions and people who have a lot to say. Each Libra representative has to be careful when talking to other people, for when they are forced to decide about something that is coming their way, or to choose sides, they suddenly realize that they might be in the wrong place and surrounded by wrong people. No partner should make them forget that they have their own opinion.",
      },
    ],
    product: {
      img: LibraProduct,
      description:
        "Fruity, sweet and cute. A Libra is cheerful and fun to be with.",
      price: 395,
      featured: {
        title: "A Libra is sweet and cheerful.",
        description:
          "No one knows why, but a Libra is always the one that will cheer us up on a bad day. She is just always there no matter what. She's amazing.",
        img: LibraFeatured,
      },
      bestSeller: true,
    },
    horoscope: {
      weekly: {
        love:
          "The sun plus Leo equals a renewed sense of enthusiasm at the start of the week. If you thought you were confident before, this fiery combo will take you over the top! Your destiny seems obvious during the midweek Mars-Jupiter trine in fire signs, so you can really go for what you want. It’s nice to have that “meant to be” feeling again for someone new!",
        life:
          "It's time to look after your ankles and feet, especially if they tend to get swollen or puffy after a day at work. This condition could be frequent. Don't ignore it or any other signs of distress in your lower legs. Get any foot problems treated as soon as possible. If you have the time and inclination, neaten up your toenails yourself or get a pedicure.",
        work:
          "You may feel vaguely as if something is wrong. There's nothing wrong with you. Don't take to heart a thoughtless comment from a customer or co-worker. This is a great time to fill in the gaps and build a foundation for any ambitious project. Speak your mind. You can shine in any health-related occupation. Things will begin to settle down. You're allowed to be lazy once in a while!",
      },
    },
  },
  {
    name: "Scorpio",
    icon: ScorpioIcon,
    img: ScorpioModel,
    title: "A Scorpio is fierced and smart.",
    description:
      "Scorpio is the most sensual sign of the zodiac. Scorpios are extremely passionate and intimacy is very important to them. They want intelligent and honest partners. Once Scorpios fall in love, they are very dedicated and faithful. However, they enter into a relationship very carefully, because sometimes they need a lot of time to build trust and respect for partners. Honesty and fairness are the two qualities that make Scorpio a great friend. They are quick-witted and intelligent, so they would feel better to be in the company of witty and fun loving people. Scorpios are fantastic in management, solving and creating.",
    summary:
      "A Scorpio is resourceful, brave, passionate, stubborn, and a true friend.",
    features: [
      {
        title: "Natural Leader",
        description:
          "Scorpio-born are passionate and assertive people. They are determined and decisive, and will research until they find out the truth. Scorpio is a great leader, always aware of the situation and also features prominently in resourcefulness.",
      },
      {
        title: "Expressive",
        description:
          "Scorpio is a Water sign and lives to experience and express emotions. Although emotions are very important for Scorpio, they manifest them differently than other water signs. In any case, you can be sure that the Scorpio will keep your secrets, whatever they may be. Scorpios hate dishonesty and they can be very jealous and suspicious, so they need to learn how to adapt more easily to different human behaviors. Scorpios are brave and therefore they have a lot of friends.",
      },
      {
        title: "Fierced",
        description:
          "Pluto is the planet of transformation and regeneration, and also the ruler of this zodiac sign. Scorpios are known by their calm and cool behavior, and by their mysterious appearance. People often say that Scorpio-born are fierce, probably because they understand very well the rules of the universe. Some Scorpio-born can look older than they actually are. They are excellent leaders because they are very dedicated to what they do.",
      },
    ],
    product: {
      img: ScorpioProduct,
      description:
        "Mysteriously interesting, A Scorpio won't tell you their secrets.",
      price: 395,
    },
    horoscope: {
      weekly: {
        love:
          "You’re one of the most confident signs, so what happens when the sun moves into proud, confident Leo at the start of the week? You get a little bolder, which is a good thing. Take those risks you’ve been thinking about! Passionate Venus enters fiery Leo later in the week, so it will be hard for people to resist your charms. You can avoid the drama that this transit tries to bring if you go to extremes to keep your private moments private.",
        life:
          "How do you use your energy every day? If you feel tired for no reason, look at your eating habits and daily routines. If you suffer from nervous tension, find the root of it and eat foods that feed your nerves and brain. It might help to cut back on caffeine or eliminate it completely. Good or bad health depends on whether your habits contribute to well-being or keep you from being well.",
        work:
          "You may still feel like you're swimming against the current. Don't let a thoughtless comment throw you off balance. Remember that other people are stressed, too. Partnerships and legal documents are favored. You can aggressively go after what you want, but pay attention to details. Work connected with money or financial planning will require extra caution. Misunderstandings are possible when you least expect them.",
      },
    },
  },
  {
    name: "Sagittarius",
    icon: SagittariusIcon,
    img: SagittariusModel,
    title: "Introducing the free and wild – Sagittarius.",
    description:
      "People born under the sign of Sagittarius are very playful and humorous, which means that they will enjoy having fun with their partners. Partners who are equally open, will certainly suit the passionate, expressive Sagittarius who is willing to try almost anything. Sagittarius is very fun and always surrounded by friends. Sagittarius-born love to laugh and enjoy the diversity of life and culture, so they will easily acquire many friends around the world. They are generous and not one of those who lecture. When it comes to family, Sagittarius is dedicated and willing to do almost anything.",
    summary:
      "A Sagittarius is generous, idealistic, and has great sense of humor.",
    features: [
      {
        title: "Optimistic",
        description:
          "Curious and energetic, Sagittarius is one of the biggest travelers among all zodiac signs. Their open mind and philosophical view motivates them to wander around the world in search of the meaning of life. Sagittarius is extrovert, optimistic and enthusiastic, and likes changes. Sagittarius-born are able to transform their thoughts into concrete actions and they will do anything to achieve their goals.",
      },
      {
        title: "Curious",
        description:
          "Like the other fire signs, Sagittarius needs to be constantly in touch with the world to experience as much as possible. The ruling planet of Sagittarius is Jupiter, the largest planet of the zodiac. Their enthusiasm has no bounds, and therefore people born under the Sagittarius sign possess a great sense of humor and an intense curiosity.",
      },
      {
        title: "Cultured",
        description:
          "Freedom is their greatest treasure, because only then they can freely travel and explore different cultures and philosophies. Because of their honesty, Sagittarius-born are often impatient and tactless when they need to say or do something, so it's important to learn to express themselves in a tolerant and socially acceptable way.",
      },
    ],
    product: {
      img: SagittariusProduct,
      description:
        "Confident and fearless, a Sagittarius never gives up a fight.",
      price: 395,
    },
    horoscope: {
      weekly: {
        love:
          "The fiery energy coming from the sun entering Leo early this week is exciting. Your perfect match now is someone who shares your enthusiasm for living a big life full of adventure. Sexy Venus enters the Lion’s confident sign later in the week, so passion is easily stirred up. Short-term flings are preferred over long-term romances now, although you aren’t ruling out commitment if you meet the right person.",
        life:
          "It might be hard to resist rich, fatty foods. No matter how closely you've followed your latest diet, you might find it easier to enjoy that cake or ice cream than push it aside. It's no great sin to veer off course now and then. Just don't make a big deal about it. Enjoy it and then start over. The cosmos will help you get on the right track.",
        work:
          "The week begins on a high note. Expect good relationships with co-workers or customers. You may have to fight for something that seems obvious. There could be unexpected mechanical breakdowns or errors in the communication of numbers. Watch the details and do your best. This is a lucky time for partnerships and social events supporting business interests. Don't compromise with bullies. Stand tall and set a good example.",
      },
    },
  },
  {
    name: "Capricorn",
    icon: CapricornIcon,
    img: CapricornModel,
    title: "Some fear the devil, others fear a Capricorn.",
    description:
      "A goat with the tail of a fish is created to face fear and create panic. It is the sign of decisions made to be protected from monsters in our minds, lives, and immediate physical surrounding. Always ready to transform into something that scares those scary things off, Capricorn speaks of each natural chain reaction of fear, where one scary thing leads to many others, rising up as defensive mechanisms that only make things worse. Immersed in their secrecy, they face the world just as they are – brave enough to never run away, but constantly afraid of their inner monsters.",
    summary:
      "A Capricorn is responsible, disciplined, self-disciplied, and a good manager.",
    features: [
      {
        title: "Responsible",
        description:
          "Capricorn is a sign that represents time and responsibility, and its representatives are traditional and often very serious by nature. These individuals possess an inner state of independence that enables significant progress both in their personal and professional lives. They are masters of self-control and have the ability to lead the way, make solid and realistic plans, and manage many people who work for them at any time. They will learn from their mistakes and get to the top based solely on their experience and expertise.",
      },
      {
        title: "Cautious",
        description:
          "Belonging to the element of Earth, like Taurus and Virgo, this is the last sign in the trio of practicality and grounding. Not only do they focus on the material world, but they have the ability to use the most out of it. Unfortunately, this element also makes them stiff and sometimes too stubborn to move from one perspective or point in a relationship. They have a hard time accepting differences of other people that are too far from their character, and out of fear might try to impose their traditional values aggressively.",
      },
      {
        title: "Practical",
        description:
          "Saturn is the ruling planet of Capricorn, and this planet represents restrictions of all kinds. Its influence makes these people practical and responsible, but also cold, distant and unforgiving, prone to the feeling of guilt and turned to the past. They need to learn to forgive in order to make their own life lighter and more positive.",
      },
    ],
    product: {
      img: CapricornProduct,
      description: "A Capricorn doesn’t need much, a minimalist, loved by all.",
      price: 395,
    },
    horoscope: {
      weekly: {
        love:
          "The conjunction between talkative Mercury and loving Venus in Cancer at the beginning of the week can make you somewhat self-conscious. If you aren’t sure about expressing your feelings now, wait until the timing feels right. Is destiny at work during the Mars-Jupiter trine midweek? It sure feels like it. Go with that “feeling” you have when you meet someone new. There are few coincidences now.",
        life:
          "You have been enjoying a fairly boisterous ride these last few weeks. To help balance your energy, keep yourself on a strict diet of moderation. This means you can eat anything you like, just do so in moderation. Continue to enjoy your usual exercise program and above all - make your sleep schedule as regular and delightful as possible. A little Lemon Verbena on your pillow at night is a lovely thing to fall asleep to!",
        work:
          "You may be feeling restless now. Watch what you say. This period could see you making big promises you can't or won't deliver. A more optimistic, aggressive energy is at work. It's lucky for travel or work-related training. If you can, accept leadership positions for short-term tasks or projects. It's likely you'll feel lazy. Do your best with what's in front of you.",
      },
    },
  },
  {
    name: "Aquarius",
    icon: AquariusIcon,
    img: AquariusModel,
    title: "She's got it all planned, she's an Aquarius.",
    description:
      "The biggest problem for Aquarius-born is the feeling that they are limited or constrained. Because of the desire for freedom and equality for all, they will always strive to ensure freedom of speech and movement. Aquarius-born have a reputation for being cold and insensitive persons, but this is just their defence mechanism against premature intimacy. They need to learn to trust others and express their emotions in a healthy way.",
    summary:
      "An Aquarius is progressive, original, independent, and a humanitarian.",
    features: [
      {
        title: "Problem Solver",
        description:
          "Aquarius-born are shy and quiet, but on the other hand they can be eccentric and energetic. However, in both cases, they are deep thinkers and highly intellectual people who love helping others. They are able to see without prejudice, on both sides, which makes them people who can easily solve problems. Aquarius is an air sign, and as such, uses his mind at every opportunity. If there is no mental stimulation, they are bored and lack a motivation to achieve the best result.",
      },
      {
        title: "Visionary",
        description:
          "The ruling planet of Aquarius, Uranus has a timid, abrupt and sometimes aggressive nature, but it also gives Aquarius visionary quality. They are capable of perceiving the future and they know exactly what they want to be doing five or ten years from now.",
      },
      {
        title: "Adaptive",
        description:
          "Uranus also gave them the power of quick and easy transformation, so they are known as thinkers, progressives and humanists. They feel good in a group or a community, so they constantly strive to be surrounded by other people. Although they can easily adapt to the energy that surrounds them, Aquarius-born have a deep need to be some time alone and away from everything, in order to restore power. People born under the Aquarius sign, look at the world as a place full of possibilities.",
      },
    ],
    product: {
      img: AquariusProduct,
      description:
        "An Aquarius is calm and mature, she's simple yet attractive.",
      price: 395,
    },
    horoscope: {
      weekly: {
        love:
          "Are you ready to be asked out (a lot), Aquarius?! When the sun enters Leo at the start of the week, you’re a very popular lover. Your unique, friendly personality is hard to resist. A fateful Mars-Jupiter trine in exciting fire signs later in the week makes you hopeful about a romantic situation, but there might not be much you can do to influence it one way or the other. If it’s meant to be, it will happen.",
        life:
          "You can be impatient when it comes to health matters. Perhaps you dislike the inconvenience of being ill and the time wasted convalescing. You could be equally impatient when it comes to getting fit or losing weight. Perhaps you give 100 percent for a while and then injure yourself or burn out. Just this once, take your time. You'll feel much better. It might help to hire a personal trainer, too.",
        work:
          "Be alert to what others tell you. Someone may be trying to help. The energy can be challenging. Be sure you've done all you can to complete assignments. Be patient with customers who can be more stressed and demanding than usual. Things will soon settle down. The energy supports a careful review of financial or technical matters. You will soon feel more like yourself and under less pressure to meet other people's expectations.",
      },
    },
  },
  {
    name: "Pisces",
    icon: PiscesIcon,
    img: PiscesModel,
    title: "Every Pisces posesses a care and loving soul.",
    description:
      "Gentle and caring, Pisces can be the best friends that may exist. In fact, they often put the needs of their friends in front of their needs. They are loyal, devoted, compassionate and whenever there is some problem in the family or among friends, they will do their best to resolve it. Deeply intuitive, Pisces can sense if something is wrong, even before it happens. Pisces are expressive and they will not hesitate to express their feelings to the people around them.",
    summary:
      "A Pisces is compassionate, artistic, intuitive, gentle, wise, and musical.",
    features: [
      {
        title: "Kind",
        description:
          "Pisces are very friendly, so they often find themselves in a company of very different people. Pisces are selfless, they are always willing to help others, without hoping to get anything back. People born under the Pisces sign have an intuitive understanding of the life cycle and thus achieve the best emotional relationship with other beings.",
      },
      {
        title: "Expressive",
        description:
          "Pisces is a Water sign and as such this zodiac sign is characterized by empathy and expressed emotional capacity. Their ruling planet is Neptune, so Pisces are more intuitive than others and have an artistic talent. Neptune is connected to music, so Pisces reveal music preferences in the earliest stages of life. They are generous, compassionate and extremely faithful and caring.",
      },
      {
        title: "Forgiving",
        description:
          "Pisces-born are known by their wisdom, but under the influence of Uranus, Pisces sometimes can take the role of a martyr, in order to catch the attention. Pisces are never judgmental and always forgiving. They are also known to be most tolerant of all the zodiac signs.",
      },
    ],
    product: {
      img: PiscesProduct,
      description:
        "A Pisces sometimes thinks too much, this scent can relax your mind.",
      price: 395,
    },
    horoscope: {
      weekly: {
        love:
          "The emotional midweek Mercury-Venus conjunction in sensitive Cancer could make you shy away from expressing how you truly feel. Moodiness and fear of rejection don’t make the best combination when you’re unsure of yourself. However, sparks fly when sexy Venus enters passionate Leo over the weekend. Now that you’re more confident, you’re ready to show a different side of yourself. You can create a lot of heat with fire signs now!",
        life:
          "You digest ideas and concepts as well as food. Some may be more easily assimilated than others. If you're taking the time to research issues associated with your well-being, go to a source you trust, one that feels right. Make sure that the next batch of ideas you consume really suits you. You'll find it easier to incorporate them into your life.",
        work:
          "You may have an opportunity to do something special for a co-worker who is struggling. Even a kind word can make a big difference. The energy is mixed now. Part of you may want to power forward with ambitious plans. At the same time, mechanical breakdowns, last-minute changes, and other unexpected events could force you to slow down and carefully review what's going on. Dig in and stick to what's expected.",
      },
    },
  },
  {
    name: "Aries",
    icon: AriesIcon,
    img: AriesModel,
    title: "The hero, the legend – Aries.",
    description:
      "Guided by the story of the Golden Fleece, an Aries is ready to be the hero of the day, fly away and carry many endangered, powerless people on their back. The power of the ram is carried on his back, for he is the gold itself, shiny and attractive to those ready for betrayal. The story of glory that isn't easy to carry is in these two horns, and if this animal doesn't get shorn, allowing change and giving someone a warm sweater, they won't have much to receive from the world. Each Aries has a task to share their position, power, gold, or physical strength with other people willingly, or the energy will be stopped in its natural flow, fear will take over, and the process of giving and receiving will hold balance at zero.",
    summary:
      "An Aries is courageous, determined, confident, enthusiastic, optimistic, honest, and passionate.",
    features: [
      {
        title: "Passionate",
        description:
          "As the first sign in the zodiac, the presence of Aries always marks the beginning of something energetic and turbulent. They are continuously looking for dynamic, speed and competition, always being the first in everything - from work to social gatherings. Thanks to its ruling planet Mars and the fact it belongs to the element of Fire (just like Leo and Sagittarius), Aries is one of the most active zodiac signs. It is in their nature to take action, sometimes before they think about it well.",
      },
      {
        title: "Strong",
        description:
          "The Sun in such high dignity gives them excellent organizational skills, so you'll rarely meet an Aries who isn't capable of finishing several things at once, often before lunch break! Their challenges show when they get impatient, aggressive and vent anger pointing it to other people. Strong personalities born under this sign have a task to fight for their goals, embracing togetherness and teamwork through this incarnation.",
      },
      {
        title: "Brave",
        description:
          "Aries rules the head and leads with the head, often literally walking head first, leaning forwards for speed and focus. Its representatives are naturally brave and rarely afraid of trial and risk. They possess youthful strength and energy, regardless of their age and quickly perform any given tasks.",
      },
    ],
    product: {
      img: AriesProduct,
      description:
        "Hot, sexy and sweet, an Aries is the perfect combination of everything.",
      price: 395,
    },
    horoscope: {
      weekly: {
        love:
          "Can you handle all the sexiness in the air when the sun enters fiery Leo at the start of the week? As a fellow fire sign, you definitely can. Let your enigmatic personality do the talking. Loving Venus also starts spending time in outgoing Leo toward the end of the week, so there’s plenty of drama to go around. You like being the center of attention now, and being in the spotlight brings the applause you desire.",
        life:
          "You may be under pressure to deliver work on a deadline. You'll find it easier to cope if you can slow down and relax. When you work, you really work, and sometimes you find it hard to switch gears. But this is the key to creating a healthy life balance. When you leave work for the day, think about pleasant things and take it easy!",
        work:
          "Trust your intuition now. What you think is likely true. At the same time, don't judge yourself harshly. This can create unnecessary worry and stress. Expect good relationships with co-workers and customers. A lively energy can have people feeling upbeat and being outspoken. Don't be afraid to face your fears. Admit it if you need help. You may reconnect with someone who has done business with you before.",
      },
    },
  },
  {
    name: "Taurus",
    icon: TaurusIcon,
    img: TaurusModel,
    title: "Taurus – she is the tough one.",
    description:
      "Being the one who betrayed their best friend, goddess Hera herself, this is an unfortunate being that has to wander the Earth in order to find freedom. As if something was always poking them behind their back, reminding them of happiness that once was, stinging and pushing forwards, they close up in their own worlds, lonely and separated from their core. To find love, a Taurus has to travel the world, change perspective or make a shift in their entire belief system and their system of values.",
    summary:
      "A Taurus is reliable, patient, practical, devoted, responsible, and stable.",
    features: [
      {
        title: "Reliable",
        description:
          "Practical and well-grounded, Taurus is the sign that harvests the fruits of labor. They feel the need to always be surrounded by love and beauty, turned to the material world, hedonism, and physical pleasures. People born with their Sun in Taurus are sensual and tactile, considering touch and taste the most important of all senses. Stable and conservative, this is one of the most reliable signs of the zodiac, ready to endure and stick to their choices until they reach the point of personal satisfaction.",
      },
      {
        title: "Stable",
        description:
          "Taurus is an Earth sign, just like Virgo and Capricorn, and has the ability to see things from a grounded, practical and realistic perspective. They find it easy to make money and stay on same projects for years, or until they are completed. What we often see as stubbornness can be interpreted as commitment, and their ability to complete tasks whatever it takes is uncanny. This makes them excellent employees, great long-term friends and partners, always being there for people they love. Earthly note makes them overprotective, conservative, or materialistic at times, with views of the world founded on their love of money and wealth.",
      },
      {
        title: "Talented",
        description:
          "The ruler of Taurus is Venus, the planet of love, attraction, beauty, satisfaction, creativity and gratitude. This tender nature will make Taurus an excellent cook, gardener, lover, and artist. They are loyal and don't like sudden changes, criticism or the chase of guilt people are often prone to, being somewhat dependable on other people and emotions they seem to be unable to let go of. Still, no matter their potential emotional challenge, these individuals have the ability to bring a practical voice of reason in any chaotic and unhealthy situation.",
      },
    ],
    product: {
      img: TaurusProduct,
      description:
        "Strong, fierce and stubborn, A Taurus never loses. She’s perfect all around.",
      price: 395,
    },
    horoscope: {
      weekly: {
        love:
          "The emotional Mercury-Venus conjunction in Cancer in the middle of the week gets in the way of you handling some important romantic business. Practicality goes out the window when feelings are involved. Loving Venus teams up with proud Leo over the weekend, so embarrassing moments seem amplified. You aren’t to the point where you can laugh at those awkward moments just yet. Maybe someday.",
        life:
          "Let your renewed enthusiasm for health and wellness fill you with a powerful desire to exercise daily and eat nourishing food. Your diet might have more of an impact on you than you realize. It would be a good idea to check for food intolerances and sensitivities so you can tailor your diet to your needs. Ongoing symptoms could suddenly disappear. That would be a real relief.",
        work:
          "This can be a powerful and unsettled period for you. There could be unfortunate events concerning communications that aren't your fault. Many people simply aren't focused on work. These are the best days to focus on your work team and acknowledge their contributions. You'll feel particularly upbeat in a few days. Jobs that involve children will be especially satisfying. Prepare for the weeks ahead now.",
      },
    },
  },
  {
    name: "Gemini",
    icon: GeminiIcon,
    img: GeminiModel,
    title: "The outgoing, romantic, and curious – Gemini.",
    description:
      "Gemini sees love first through communication and verbal contact, and find it as important as physical contact with their partner. When these two combine, obstacles all seem to fade. Inquisitive and always ready to flirt, a Gemini could spend a lot of time with different lovers until they find the right one who is able to match their intellect and energy. They need excitement, variety and passion, and when they find the right person, a lover, a friend and someone to talk to combined into one, they will be faithful and determined to always treasure their heart.",
    summary:
      "A Gemini is gentle, affectionate, curious, adaptable, has the ability to learn quickly and exchange ideas.",
    features: [
      {
        title: "Communicative",
        description:
          "Expressive and quick-witted, Gemini represents two different personalities in one and you will never be sure which one you will face. They are sociable, communicative and ready for fun, with a tendency to suddenly get serious, thoughtful and restless. They are fascinated with the world itself, extremely curious, with a constant feeling that there is not enough time to experience everything they want to see.",
      },
      {
        title: "Romantic",
        description:
          "The sign of Gemini belongs to the element of Air, accompanying Libra and Aquarius, and this connects it to all aspects of the mind. It is ruled by Mercury, the planet that represents communication, writing, and movement. People born under this Sun sign often have a feeling that their other half is missing, so they are forever seeking new friends, mentors, colleagues and people to talk to.",
      },
      {
        title: "Open-minded",
        description:
          "Gemini's changeable and open mind makes them excellent artists, especially writers and journalists, and their skills and flexibility make them shine in trade, driving and team sports. This is a versatile, inquisitive, fun loving sign, born with a wish to experience everything there is out there, in the world. This makes their character inspiring, and never boring.",
      },
    ],
    product: {
      img: GeminiProduct,
      description: "Fresh, new and active scent. Stay alert like a Gemini.",
      price: 395,
      bestSeller: true,
    },
    horoscope: {
      weekly: {
        love:
          "You’re full of confidence when the sun pairs up with bold Leo early in the week. Your approach might not always result in a yes, but knowing you’re putting it all out there is satisfying in its own way. A fortunate Mars-Jupiter trine midweek opens you up to new experiences, and you’re anxious to show a date a good time. Don’t be surprised when you feel like something was “meant to be.”",
        life:
          "The pounds could pile on if you aren't careful. If you're going to be doing a lot of socializing, the temptation to indulge could be too much. The weekend might be the worst time, but there are a few measures you can take to help yourself. Drink plenty of water - it will help you feel full. Drink hot water and lemon to dull a sweet tooth. Take control and don't let your discipline disintegrate.",
        work:
          "There is a powerful energy now that can make other people nervous or distracted. Do your best to focus on routines. Be careful not to overindulge in homemade treats at the office. This is a good time to share a meal with the people you supervise. It's a great time for cleaning and organizing to make your workspace or wardrobe more presentable.",
      },
    },
  },
  {
    name: "Cancer",
    icon: CancerIcon,
    img: CancerModel,
    title: "Proud queen of the moon - Cancer.",
    description:
      "Sent to this Earth by something they believe in, only to mess with someone bigger than they are, this isn't an animal aware of their strength. Patriotism can make them endanger their own wellbeing, fighting for someone else's cause, as if others can become their higher power. The Crab knows where they're going, but this is often in a wrong direction, at least until they learn their lessons and start relying solely on themselves.",
    summary:
      "A Cancer is tenacious, highly imaginative, loyal, emotional, sympathetic, and persuasive.",
    features: [
      {
        title: "Sympathetic",
        description:
          "Deeply intuitive and sentimental, Cancer can be one of the most challenging zodiac signs to get to know. They are very emotional and sensitive, and care deeply about matters of the family and their home. Cancer is sympathetic and attached to people they keep close. Those born with their Sun in Cancer are very loyal and able to empathize with other people's pain and suffering.",
      },
      {
        title: "Understanding",
        description:
          "The sign of Cancer belongs to the element of Water, just like Scorpio and Pisces. Guided by emotion and their heart, they could have a hard time blending into the world around them. Being ruled by the Moon, phases of the lunar cycle deepen their internal mysteries and create fleeting emotional patterns that are beyond their control. As children, they don't have enough coping and defensive mechanisms for the outer world, and have to be approached with care and understanding, for that is what they give in return.",
      },
      {
        title: "Peaceful",
        description:
          "Lack of patience or even love will manifest through mood swings later in life, and even selfishness, self-pity or manipulation. They are quick to help others, just as they are quick to avoid conflict, and rarely benefit from close combat of any kind, always choosing to hit someone stronger, bigger, or more powerful than they imagined. When at peace with their life choices, Cancer representatives will be happy and content to be surrounded by a loving family and harmony in their home.",
      },
    ],
    product: {
      img: CancerProduct,
      description: "The cutest of them all, a Cancer can melt anyone’s heart.",
      price: 395,
    },
    horoscope: {
      weekly: {
        love:
          "The middle of the week brings a Mercury-Venus conjunction in your sign that makes communicating more comfortable than it’s been lately. This is a good time to return texts and DMs to people who’ve been trying to get your attention. Passionate Venus teams up with fiery Leo over the weekend, which helps bring you out of your shell. Your love life blossoms when you don’t shy away from being in the spotlight.",
        life:
          "It's time to cleanse your system, especially if you feel more tired than usual. Exercise is a great solution, especially if you do it regularly. You need to get a good sweat going to coax the impurities out of your body. If you combine this with a diet of fresh food, juices, and plenty of water, your skin will glow and you'll feel refreshed.",
        work:
          "You may feel you've reached some sort of crossroads. Do as little as possible without being lazy. There could be a slowdown or confusion concerning any sort of trade. Casual conversations hold the seeds of surprising new opportunities. These are positive days for teambuilding and sharing a meal with co-workers. Your spirits will be high. This period is good for catching up on unfinished business.",
      },
    },
  },
];
