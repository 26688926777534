import Slide1Img from 'assets/img/about/slide-1.jpg';
import Slide1ImgSmall from 'assets/img/about/slide-1-small.jpg';
import Slide2Img from 'assets/img/about/slide-2.jpg';
import Slide2ImgSmall from 'assets/img/about/slide-2-small.jpg';
import Slide3Img from 'assets/img/about/slide-3.jpg';
import Slide3ImgSmall from 'assets/img/about/slide-3-small.jpg';

export const aboutSlides = [
  {
    title: 'A perfume isn’t just a scent, it adds confidence.',
    summary: 'Defining Confidence',
    img: [
      Slide1Img,
      Slide1ImgSmall,
    ],
    zodiac: 'Libra'
  },
  {
    title: 'Fashion isn’t just external, it mirrors your inner self.',
    summary: 'Inner Fashion',
    img: [
      Slide2Img,
      Slide2ImgSmall,
    ],
    zodiac: 'Leo'
  },
  {
    title: 'We believe positivity brings joy to all corners.',
    summary: 'Positivity & Joy',
    img: [
      Slide3Img,
      Slide3ImgSmall,
    ],
    zodiac: 'Aries'
  }
];