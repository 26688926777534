import "./WeeklyHoroscope.scss";

import { Col, Row } from "reactstrap";
import React, { useState } from "react";
import { animated, useSpring, useTrail } from "react-spring";

import VisibilitySensor from "react-visibility-sensor";
import { connect } from "react-redux";

const WeeklyHoroscope = ({ zodiac }) => {
  // Animation
  const [isVisible, setIsVisible] = useState(false);
  const animations = [
    {
      fadeIn: useSpring({
        opacity: isVisible ? 1 : 0,
      }),
      slideLeftTrail: useTrail(3, {
        position: "relative",
        left: isVisible ? "0" : "30px",
      }),
      slideUp: useSpring({
        position: "relative",
        top: isVisible ? "0" : "30px",
      }),
    },
  ];

  return (
    <VisibilitySensor
      onChange={setIsVisible}
      partialVisibility
      minTopValue="400"
    >
      <animated.div style={animations[0].fadeIn}>
        <div className="weekly-horoscope space-bigger">
          <Row>
            <Col lg={{ size: 2, offset: 1 }} className="space-smaller">
              <div className="text-uppercase text-smaller">
                Weekly Horoscope
              </div>
              <h4 className="space-smaller">
                This week’s horoscope for {zodiac.name}.
              </h4>
            </Col>
            <Col md="4" lg={{ size: 3, offset: 1 }} className="space-smaller">
              <animated.div style={animations[0].slideLeftTrail[0]}>
                <div className="weekly-horoscope-number text-smaller">01</div>
                <h4 className="space-smaller">Love</h4>
                <p className="text-muted">
                  {zodiac.horoscope.weekly.love.substring(0, 300) + "..."}
                </p>
              </animated.div>
            </Col>
            <Col md="4" lg="2" className="space-smaller">
              <animated.div style={animations[0].slideLeftTrail[1]}>
                <div className="weekly-horoscope-number text-smaller">02</div>
                <h4 className="space-smaller">Life</h4>
                <p className="text-muted">
                  {zodiac.horoscope.weekly.life.substring(0, 150) + "..."}
                </p>
              </animated.div>
            </Col>
            <Col md="4" lg="2" className="space-smaller">
              <animated.div style={animations[0].slideLeftTrail[2]}>
                <div className="weekly-horoscope-number text-smaller">03</div>
                <h4 className="space-smaller">Work</h4>
                <p className="text-muted">
                  {zodiac.horoscope.weekly.work.substring(0, 150) + "..."}
                </p>
              </animated.div>
            </Col>
            <Col lg={{ size: 3, offset: 4 }}>
              <animated.div style={animations[0].slideUp}>
                <button type="button" className="btn">
                  Read more
                </button>
              </animated.div>
            </Col>
          </Row>
        </div>
      </animated.div>
    </VisibilitySensor>
  );
};

const mapStateToProps = (state) => ({
  zodiac: state.shop.zodiacs[state.shop.currentZodiac],
});

export default connect(mapStateToProps)(WeeklyHoroscope);
