import "./Navbar.scss";

import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";

import BagIcon from "assets/icon/bag.svg";
import HeartIcon from "assets/icon/heart.svg";
import LogoBadgeBlack from "assets/logo/badge-black.svg";
import LogoBadgeWhite from "assets/logo/badge-white.svg";
import MagnifyingGlassIcon from "assets/icon/magnifying-glass.svg";
import { NavLink } from "react-router-dom";
import UserIcon from "assets/icon/user.svg";
import { matchPath } from "react-router";
import { useMemo } from "react";
import { withRouter } from "react-router-dom";

const Navbar = ({ location }) => {
  const navs = [
    {
      key: "home",
      path: "/",
      name: "Home",
      exact: true,
    },
    {
      key: "shop",
      path: "/shop",
      name: "Shop",
    },
    {
      key: "blog",
      path: "/blog",
      name: "Blog",
    },
    {
      key: "about",
      path: "/about",
      name: "About",
    },
    {
      key: "contact",
      path: "/contact",
      name: "Contact",
    },
  ];

  // Handle invert
  const { pathname } = location;
  const matchAbout = matchPath(pathname, {
    path: "/about",
    exact: true,
  });
  const isUsingInvert = useMemo(() => matchAbout || false, [matchAbout]);

  // Handle window resize
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setScreenSize]);

  // Animation
  const [isMenuActive, setIsMenuActive] = useState(false);
  const animations = [
    {
      fadeIn: useSpring({
        opacity: isMenuActive ? 1 : 0,
      }),
      fadeExpand: useSpring({
        opacity: isMenuActive ? 1 : 0,
        margin: isMenuActive ? "30px 0" : "20px 0",
      }),
    },
  ];

  return (
    <div className={"navbar-container" + (isUsingInvert ? " invert" : "")}>
      <div className={"navbar" + (isMenuActive ? " active" : "")}>
        <button
          className={"menu-toggler" + (isMenuActive ? " active" : "")}
          onClick={() => setIsMenuActive(!isMenuActive)}
        >
          <div></div>
          <div></div>
        </button>

        <animated.ul
          className={"nav-left" + (isMenuActive ? " active" : "")}
          style={{
            display: animations[0].fadeIn.opacity.interpolate((v) =>
              v === 0 && screenSize < 768 ? "none" : ""
            ),
          }}
        >
          {navs.map((nav, i) => (
            <li key={i}>
              <NavLink
                exact={nav.exact || false}
                to={nav.path}
                activeClassName="active"
                onClick={() => setIsMenuActive(false)}
              >
                <span>{nav.name}</span>
              </NavLink>
            </li>
          ))}
        </animated.ul>

        <div className="logo">
          <a href="/">
            <img className="logo-black" src={LogoBadgeBlack} alt="Logo" />
            <img className="logo-white" src={LogoBadgeWhite} alt="Logo" />
          </a>
        </div>

        <animated.ul
          className={"nav-right" + (isMenuActive ? " active" : "")}
          style={{
            display: animations[0].fadeIn.opacity.interpolate((v) =>
              v === 0 && screenSize < 768 ? "none" : ""
            ),
          }}
        >
          <li>
            <button type="button" className="btn-anchor">
              <img src={MagnifyingGlassIcon} alt="Search Icon" />{" "}
              <span className="alt-text">Search</span>
            </button>
          </li>
          <li>
            <button type="button" className="btn-anchor">
              <img src={UserIcon} alt="User Icon" />{" "}
              <span className="alt-text">Account</span>
            </button>
          </li>
          <li>
            <button type="button" className="btn-anchor">
              <img src={HeartIcon} alt="Heart Icon" /> 0{" "}
              <span className="alt-text">Favorites</span>
            </button>
          </li>
          <li>
            <button type="button" className="btn-anchor">
              <img src={BagIcon} alt="Bag Icon" />{" "}
              <span className="alt-text">Shopping bag</span>
            </button>
          </li>
        </animated.ul>
      </div>

      <animated.div
        className="nav-center"
        style={{
          ...animations[0].fadeIn,
          visibility: animations[0].fadeIn.opacity.interpolate((v) =>
            v === 0 ? "hidden" : "visible"
          ),
        }}
      >
        <ul>
          {navs.map((nav, i) => (
            <animated.li key={i} style={animations[0].fadeExpand}>
              <NavLink
                exact={nav.exact || false}
                to={nav.path}
                activeClassName="active"
                onClick={() => setIsMenuActive(false)}
              >
                <span>{nav.name}</span>
              </NavLink>
            </animated.li>
          ))}
        </ul>
      </animated.div>
    </div>
  );
};

export default withRouter(Navbar);
