import { Col, Row } from 'reactstrap';
import React, { useState } from 'react';
import { animated, useSpring, useTrail } from 'react-spring';

import VisibilitySensor from 'react-visibility-sensor';
import { images } from 'samples/instagram';

const Instagram = () => {
  // Animation
  const [ isVisible, setIsVisible ] = useState(false);
  const animations = [
    {
      fadeIn: useSpring({
        opacity: isVisible ? 1 : 0,
      }),
      fadeUpTrail: useTrail(images.length, {
        position: 'relative',
        top: isVisible ? '0' : '30px',
        opacity: isVisible ? 1 : 0,
      })
    }
  ];

  return (
    <VisibilitySensor onChange={ setIsVisible } active={ !isVisible } partialVisibility minTopValue="200">
      <animated.div style={ animations[0].fadeIn }>
        <div className="space-bigger">
          <h3>
            Instagram
          </h3>
          <div className="space">
            <Row>
              { images.map((image, i) =>
                <Col xs="4" xl="4" key={ i }>
                  <animated.div style={ animations[0].fadeUpTrail[i] }>
                    <a href="https://www.instagram.com/sugar.pep/" target="_blank" rel="noopener noreferrer">
                      <div className="background-image square space-smaller" style={{
                        backgroundImage: 'url(' + image + ')'
                      }}></div>
                    </a>
                  </animated.div>
                </Col>
              ) }
            </Row>
          </div>
        </div>
      </animated.div>
    </VisibilitySensor>
  );
}

export default Instagram;
