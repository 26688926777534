import "./Contact.scss";

import React, { useState } from "react";

import Header from "components/Header";
import HeaderImage from "assets/img/contact/header.jpg";
import TextInput from "components/TextInput";

// import axios from "axios";

const Contact = () => {
  const formObj = {
    email: "",
    name: "",
    subject: "",
    message: "",
  };
  const [form, setForm] = useState(formObj);
  const { email, name, subject, message } = form;

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send an email to message sender
    //     axios.post(cloudFunctions.sendmail, {
    //       from: `Sugar & Pepper <hello@sugarpep.com>`,
    //       to: email,
    //       replyTo: 'hello@sugarpep.com',
    //       subject: 'Thanks for contacting us.',
    //       message: `
    // <!DOCTYPE html
    //   PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    // <html xmlns="http://www.w3.org/1999/xhtml">

    //   <head>
    //     <meta charset="utf-8" />
    //     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    //     <title>
    //       ${subject}
    //     </title>
    //     <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    //   </head>

    //   <body style="margin: 10px 0; padding: 10px 0; background-color: #f8f8f8;">
    //     <table border="0" cellpadding="0" cellspacing="0" width="100%">
    //       <tr>
    //         <td style="padding: 0px 10px 0px 10px;">
    //           <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
    //             <tr>
    //               <td width="600" style="font-family: Arial, sans-serif; line-height: 2rem; padding: 30px 0px 30px 0px;">
    //                 <img src="https://sugarpep.com/favicons/apple-touch-icon.png" height="70">
    //               </td>
    //             </tr>
    //             <tr>
    //               <td width="600"
    //                 style="font-family: Arial, sans-serif; font-size: 25px; line-height: 2rem; padding: 10px 0px 10px 0px;">
    //                 Thanks for contacting us.
    //               </td>
    //             </tr>
    //             <tr>
    //               <td width="600"
    //                 style="font-family: Arial, sans-serif; font-size: 16px; line-height: 2rem; padding: 30px 0px 60px 0px;">
    //                 Hello ${name},
    //                 <br/>
    //                 <br/>
    //                 Thank you for getting in touch with us. We have received your message with subject "${subject}". We will get back to you soon.
    //                 <br/>
    //                 <br/>
    //                 Your message is:
    //                 <br/>
    //                 <pre>${message}</pre>
    //                 <br/>
    //                 Regards,<br/>
    //                 Sugar & Pepper
    //               </td>
    //             </tr>
    //           </table>
    //           <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
    //             <tr>
    //               <td style="border-top: 1px solid #cccccc;"></td>
    //             </tr>
    //           </table>
    //           <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
    //             <tr>
    //               <td width="200" valign="top"
    //                 style="font-family: Arial, sans-serif; font-size: 16px; line-height: 2rem; padding: 30px 0px 0px 0px;">
    //                 <strong>Contact</strong>
    //               </td>
    //               <td width="400" valign="top"
    //                 style="font-family: Arial, sans-serif; font-size: 16px; line-height: 2rem; padding: 30px 0px 0px 0px;">
    //                 Sugar & Pepper
    //                 <br>
    //                 11/45 Nirvana Sathorn
    //                 <br>
    //                 Kallapapruk Road
    //                 <br>
    //                 Jomthong Bangjhunthien
    //                 <br>
    //                 Bangkok Thailand 10150
    //                 <br>
    //                 <br>
    //                 <a href="mailto:hello@sugarpep.com" style="color: #1196f4; text-decoration: none;">hello@sugarpep.com</a>
    //                 <br>
    //                 <a href="tel:0819899505" style="color: #1196f4; text-decoration: none;">+66 0 81 989 9505</a>
    //               </td>
    //             </tr>
    //           </table>
    //           <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
    //             <tr>
    //               <td width="600" valign="top"
    //                 style="font-family: Arial, sans-serif; font-size: 16px; line-height: 2rem; padding: 30px 0px 60px 0px;">
    //                 &copy; Sugar & Pepper ${new Date().getFullYear()}
    //               </td>
    //             </tr>
    //           </table>
    //         </td>
    //       </tr>
    //     </table>
    //   </body>
    // </html>
    //       `,
    //     }).then(() => {
    //       // Send another email to SugarPep admin
    //       axios.post(cloudFunctions.sendmail, {
    //         from: `Sugar & Pepper <hello@sugarpep.com>`,
    //         to: 'hello@sugarpep.com',
    //         replyTo: email,
    //         subject: `${subject} | A message from ${name}.`,
    //         message: `
    // <!DOCTYPE html
    //   PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    // <html xmlns="http://www.w3.org/1999/xhtml">

    //   <head>
    //     <meta charset="utf-8" />
    //     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    //     <title>
    //       ${subject}
    //     </title>
    //     <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    //   </head>

    //   <body style="margin: 10px 0; padding: 10px 0; background-color: #f8f8f8;">
    //     <table border="0" cellpadding="0" cellspacing="0" width="100%">
    //       <tr>
    //         <td style="padding: 0px 10px 0px 10px;">
    //           <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
    //             <tr>
    //               <td width="600" style="font-family: Arial, sans-serif; line-height: 2rem; padding: 30px 0px 30px 0px;">
    //                 <img src="https://sugarpep.com/favicons/apple-touch-icon.png" height="70">
    //               </td>
    //             </tr>
    //             <tr>
    //               <td width="600"
    //                 style="font-family: Arial, sans-serif; font-size: 25px; line-height: 2rem; padding: 10px 0px 10px 0px;">
    //                 ${subject}
    //               </td>
    //             </tr>
    //             <tr>
    //               <td width="600"
    //                 style="font-family: Arial, sans-serif; font-size: 16px; line-height: 2rem; padding: 30px 0px 60px 0px;">
    //                 ${message}
    //               </td>
    //             </tr>
    //           </table>
    //           <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
    //             <tr>
    //               <td style="border-top: 1px solid #cccccc;"></td>
    //             </tr>
    //           </table>
    //           <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
    //             <tr>
    //               <td width="200" valign="top"
    //                 style="font-family: Arial, sans-serif; font-size: 16px; line-height: 2rem; padding: 30px 0px 0px 0px;">
    //                 <strong>Contact</strong>
    //               </td>
    //               <td width="400" valign="top"
    //                 style="font-family: Arial, sans-serif; font-size: 16px; line-height: 2rem; padding: 30px 0px 0px 0px;">
    //                 Sugar & Pepper
    //                 <br>
    //                 11/45 Nirvana Sathorn
    //                 <br>
    //                 Kallapapruk Road
    //                 <br>
    //                 Jomthong Bangjhunthien
    //                 <br>
    //                 Bangkok Thailand 10150
    //                 <br>
    //                 <br>
    //                 <a href="mailto:hello@sugarpep.com" style="color: #1196f4; text-decoration: none;">hello@sugarpep.com</a>
    //                 <br>
    //                 <a href="tel:0819899505" style="color: #1196f4; text-decoration: none;">+66 0 81 989 9505</a>
    //               </td>
    //             </tr>
    //           </table>
    //           <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
    //             <tr>
    //               <td width="600" valign="top"
    //                 style="font-family: Arial, sans-serif; font-size: 16px; line-height: 2rem; padding: 30px 0px 60px 0px;">
    //                 &copy; Sugar & Pepper ${new Date().getFullYear()}
    //               </td>
    //             </tr>
    //           </table>
    //         </td>
    //       </tr>
    //     </table>
    //   </body>
    // </html>
    //       `,
    //       }).then(() => {
    //         setForm(formObj);
    //       });
    //     }).catch(console.error);
  };

  return (
    <div className="contact-section">
      <Header
        image={HeaderImage}
        title={`Hello${
          name ? " " + name : ""
        }, your inputs are valuable to us.`}
        subtitle="We appreciate your suggestions, complaints, and other inquiry."
      >
        <form onSubmit={handleSubmit}>
          <div className="field">
            <TextInput
              placeholder="Your name"
              value={name}
              required={true}
              onChange={(e) =>
                setForm({
                  ...form,
                  name: e.target.value,
                })
              }
            />
            <label>Your name</label>
          </div>
          <div className="field">
            <TextInput
              type="email"
              placeholder="Your email address"
              value={email}
              required={true}
              onChange={(e) =>
                setForm({
                  ...form,
                  email: e.target.value,
                })
              }
            />
            <label>Your email address</label>
          </div>
          <div className="field">
            <TextInput
              placeholder="Subject"
              value={subject}
              required={true}
              onChange={(e) =>
                setForm({
                  ...form,
                  subject: e.target.value,
                })
              }
            />
            <label>Subject</label>
          </div>
          <div className="field">
            <textarea
              placeholder="Message"
              value={message}
              required={true}
              onChange={(e) =>
                setForm({
                  ...form,
                  message: e.target.value,
                })
              }
            />
          </div>
          <button type="submit" className="btn btn-center">
            Submit your message
          </button>
        </form>
      </Header>
    </div>
  );
};

export default Contact;
