import BlogShowcase from "components/BlogShowcase";
import React from "react";
import { connect } from "react-redux";
import { fetch } from "../../redux/actions/blogActions";
import { useEffect } from "react";
import { useState } from "react";

const FeaturedBlogShowcase = (props) => {
  const { blog } = props;
  const { entries } = blog;

  const [filteredEntries, setFilteredEntries] = useState([]);

  useEffect(() => {
    if (!filteredEntries.length) {
      entries.filter((entry) => entry.featured);
    }
  }, [entries, filteredEntries.length]);

  useEffect(() => {
    if (filteredEntries.length < 3) {
      const lengthDiff = 3 - filteredEntries.length;
      const fallbackEntries = entries.slice(0, lengthDiff);
      setFilteredEntries([...filteredEntries, ...fallbackEntries]);
    }
  }, [entries, filteredEntries]);

  return (
    <BlogShowcase
      entries={filteredEntries}
      heading={[
        "Our ",
        <span className="text-italic text-underlined" key="0">
          Fabulous
        </span>,
        " Blog",
      ]}
    />
  );
};

const mapStateToProps = (state) => ({
  blog: state.blog,
});

export default connect(mapStateToProps, { fetch })(FeaturedBlogShowcase);
