import "./FeaturedBlogEntry.scss";

import React, { useEffect, useState } from "react";
import { animated, useSpring, useTrail } from "react-spring";

import { Link } from "react-router-dom";
import Slider from "react-slick";
import VisibilitySensor from "react-visibility-sensor";
import { connect } from "react-redux";
import { useMemo } from "react";

const FeaturedBlogEntry = ({ blog }) => {
  const { entries } = blog;

  // Filter featured entries
  const featuredEntries = useMemo(
    () => entries.filter((entry) => entry.featured),
    [entries]
  );

  // Slick slider settings
  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 8000,
    fade: true,
    infinite: true,
    speed: 1300,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (i) => {
      setIsSlideChanged(false);
    },
  };

  // Animation
  const [isVisible, setIsVisible] = useState(false);
  const [isSlideChanged, setIsSlideChanged] = useState(false);
  const animations = [
    {
      fadeIn: useSpring({
        opacity: isVisible ? 1 : 0,
      }),
    },
    {
      fadeLeftTrail: useTrail(3, {
        position: "relative",
        left: isSlideChanged ? "0" : "20px",
        opacity: isSlideChanged ? 1 : 0,
      }),
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isVisible) {
        setIsSlideChanged(true);
      }
    }, 800);

    return () => clearTimeout(timer);
  }, [isVisible]);

  return (
    <VisibilitySensor
      onChange={setIsVisible}
      active={!isVisible}
      partialVisibility
    >
      <animated.div style={animations[0].fadeIn}>
        <Slider {...settings}>
          {featuredEntries
            ? featuredEntries.map((entry, i) => (
                <div key={i}>
                  <div
                    className="featured-blog-entry-container background-image"
                    style={{
                      backgroundImage: "url(" + entry.thumbnail + ")",
                    }}
                  >
                    <div className="full-cover overlay">
                      <div className="featured-blog-entry-text">
                        <animated.div
                          className="text-uppercase text-smaller"
                          style={animations[1].fadeLeftTrail[0]}
                        >
                          Featured
                        </animated.div>
                        <animated.h3
                          className="space-smaller"
                          style={animations[1].fadeLeftTrail[1]}
                        >
                          {entry.title}
                        </animated.h3>
                        <animated.div style={animations[1].fadeLeftTrail[2]}>
                          <Link
                            to={"/blog/" + entry.slug}
                            className="btn btn-left btn-white"
                          >
                            Read more
                          </Link>
                        </animated.div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </Slider>
      </animated.div>
    </VisibilitySensor>
  );
};

const mapStateToProps = (state) => ({
  blog: state.blog,
});

export default connect(mapStateToProps)(FeaturedBlogEntry);
