import './Ads.scss';

import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';

import VisibilitySensor from 'react-visibility-sensor';
import image from 'assets/img/blog/ads.png';

const Ads = () => {
  // Animation
  const [ isVisible, setIsVisible ] = useState(false);
  const animations = [
    {
      fadeIn: useSpring({
        opacity: isVisible ? 1 : 0,
      })
    }
  ];

  return (
    <VisibilitySensor onChange={ setIsVisible } active={ !isVisible } partialVisibility minTopValue="300">
      <animated.div className="ads-container" style={ animations[0].fadeIn }>
        <img src={image} alt="Advertisement" />
        <a
          href="/shop"
          className="btn"
          >
          Discover
        </a>
      </animated.div>
    </VisibilitySensor>
  );
}

export default Ads;
