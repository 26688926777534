import { Col, Row } from "reactstrap";
import React, { useMemo, useState } from "react";
import { animated, useSpring, useTrail } from "react-spring";

import ProductThumbnail from "components/ProductThumbnail";
import VisibilitySensor from "react-visibility-sensor";
import { connect } from "react-redux";

const BestSellers = (props) => {
  const { zodiacs } = props;
  const bestSellers = useMemo(
    () => zodiacs.filter((zodiac) => zodiac.product.bestSeller),
    [zodiacs]
  );

  // Animation
  const [isVisible, setIsVisible] = useState(false);
  const animations = [
    {
      fadeInUp: useSpring({
        position: "relative",
        top: isVisible ? "0" : "20px",
        opacity: isVisible ? 1 : 0,
      }),
      fadeInUpTrail: useTrail(bestSellers.length, {
        position: "relative",
        top: isVisible ? "0" : "20px",
        opacity: isVisible ? 1 : 0,
      }),
    },
  ];

  return (
    <VisibilitySensor
      onChange={setIsVisible}
      partialVisibility
      minTopValue="300"
    >
      <animated.div style={animations[0].fadeInUp}>
        <div className="best-sellers space text-center">
          <h2>Best Sellers</h2>
          <Row className="space">
            {bestSellers
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((bestSeller, i) => (
                <Col xs="6" lg="3" key={i}>
                  <a href={"/shop/" + bestSeller.name.toLowerCase()}>
                    <animated.div style={animations[0].fadeInUpTrail[i]}>
                      <ProductThumbnail
                        product={{
                          name: bestSeller.name,
                          icon: bestSeller.icon,
                          img: bestSeller.product.img,
                          description: bestSeller.product.description,
                          price: bestSeller.product.price,
                        }}
                      />
                    </animated.div>
                  </a>
                </Col>
              ))}
          </Row>
          <a href="/shop" className="link-underlined">
            View All Products
          </a>
        </div>
      </animated.div>
    </VisibilitySensor>
  );
};

const mapStateToProps = (state) => ({
  zodiacs: state.shop.zodiacs,
});

export default connect(mapStateToProps)(BestSellers);
