import React, { useState } from "react";
import { animated, useSpring, useTrail } from "react-spring";

import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import { connect } from "react-redux";
import { useMemo } from "react";

const PopularTopics = ({ blog }) => {
  const { entries } = blog;
  const sortedEntries = useMemo(
    () => [...entries].sort((a, b) => (a.views < b.views ? 1 : -1)).slice(0, 4),
    [entries]
  );

  // Animation
  const [isVisible, setIsVisible] = useState(false);
  const animations = [
    {
      fadeIn: useSpring({
        opacity: isVisible ? 1 : 0,
      }),
      fadeUpTrail: useTrail(sortedEntries.length, {
        position: "relative",
        top: isVisible ? "0" : "30px",
        opacity: isVisible ? 1 : 0,
      }),
    },
  ];

  return (
    <VisibilitySensor
      onChange={setIsVisible}
      active={!isVisible}
      partialVisibility
      minTopValue="200"
    >
      <animated.div style={animations[0].fadeIn}>
        <h3>Popular Topics</h3>
        {sortedEntries.map((entry, i) => (
          <animated.div style={animations[0].fadeUpTrail[i]} key={i}>
            <Link to={"/blog/" + entry.slug}>
              <div className="space" key={i}>
                <div className="text-uppercase text-smaller">
                  {entry.category.name}
                </div>
                <h5 className="space-smaller">{entry.title}</h5>
              </div>
            </Link>
          </animated.div>
        ))}
      </animated.div>
    </VisibilitySensor>
  );
};

const mapStateToProps = (state) => ({
  blog: state.blog,
});

export default connect(mapStateToProps)(PopularTopics);
