import { Container } from "reactstrap";
import ProductSpecimen from "components/ProductSpecimen";
import React from "react";
import WeeklyHoroscope from "components/WeeklyHoroscope";
import ZodiacBlogShowcase from "components/ZodiacBlogShowcase";
import ZodiacProfile from "components/ZodiacProfile";
import ZodiacScrollbar from "components/ZodiacScrollbar";
import { connect } from "react-redux";
import { fetch as fetchBlog } from "../../redux/actions/blogActions";
import { updateZodiac } from "../../redux/actions/shopActions";
import { useEffect } from "react";

const Shop = ({
  blog,
  fetchBlog,
  match,
  updateZodiac,
  zodiacs,
  currentZodiac,
}) => {
  // Fetch entries
  useEffect(() => {
    if (!blog.entries.length) {
      fetchBlog();
    }
  }, [blog.entries.length, fetchBlog]);

  useEffect(() => {
    const urlZodiac = match.params.zodiac;

    if (zodiacs.length && urlZodiac) {
      const zodiac = zodiacs.find((zodiac, i) => {
        if (zodiac.name) {
          return zodiac.name.toLowerCase() === urlZodiac.toLowerCase();
        }
        return true;
      });
      const index = zodiacs.indexOf(zodiac);

      if (currentZodiac !== index) {
        updateZodiac(index);
      }
    }
  }, [currentZodiac, match.params.zodiac, updateZodiac, zodiacs]);

  return (
    <>
      <div className="page-margin">
        <ZodiacScrollbar />
        <Container>
          <ZodiacProfile />
          <ProductSpecimen />
          <WeeklyHoroscope />
          <ZodiacBlogShowcase />
        </Container>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    blog: state.blog,
    zodiacs: state.shop.zodiacs,
    currentZodiac: state.shop.currentZodiac,
  }),
  {
    fetchBlog,
    updateZodiac,
  }
)(Shop);
