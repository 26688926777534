import "./ProductSpecimen.scss";

import { Col, Row } from "reactstrap";
import React, { useState } from "react";
import { animated, useSpring, useTrail } from "react-spring";

import VisibilitySensor from "react-visibility-sensor";
import bahtIcon from "assets/icon/baht.svg";
import { connect } from "react-redux";

const ProductSpecimen = ({ zodiac }) => {
  const { product } = zodiac;

  // Animation
  const [isVisible, setIsVisible] = useState(false);
  const animations = [
    {
      fadeIn: useSpring({
        opacity: isVisible ? 1 : 0,
      }),
      slideUpTrail: useTrail(4, {
        position: "relative",
        top: isVisible ? "0" : "50px",
      }),
    },
  ];

  return (
    <VisibilitySensor
      onChange={setIsVisible}
      partialVisibility
      minTopValue="400"
    >
      <animated.div style={animations[0].fadeIn}>
        <Row className="space">
          <Col lg={{ size: 10, offset: 1 }}>
            <div className="product-specimen">
              <div className="product-specimen-text">
                <animated.div
                  className="text-uppercase text-smaller"
                  style={animations[0].slideUpTrail[0]}
                >
                  Specimen
                </animated.div>
                <animated.h4
                  className="space"
                  style={animations[0].slideUpTrail[1]}
                >
                  {zodiac.name} Sugar Pep Rolling Oil Perfume
                </animated.h4>
                <animated.div
                  className="text-smaller"
                  style={animations[0].slideUpTrail[2]}
                >
                  <img src={bahtIcon} alt="Baht" /> {product.price}
                </animated.div>
                <animated.div
                  className="product-specimen-button"
                  style={animations[0].slideUpTrail[3]}
                >
                  <button type="button" className="btn btn-left">
                    Add to cart
                  </button>
                </animated.div>
              </div>
              <div className="product-specimen-image">
                <img
                  src={product.img}
                  alt={zodiac.name + " Sugar Pep Rolling Oil Perfume"}
                  width="50%"
                />
              </div>
            </div>
          </Col>
        </Row>
      </animated.div>
    </VisibilitySensor>
  );
};

const mapStateToProps = (state) => ({
  zodiac: state.shop.zodiacs[state.shop.currentZodiac],
});

export default connect(mapStateToProps)(ProductSpecimen);
