import React, { useState } from "react";

import TextInput from "components/TextInput";
import { useCallback } from "react";

const NewsletterForm = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
  }, []);

  return (
    <form className="newsletter-form" onSubmit={handleSubmit}>
      <div className="text-uppercase text-smaller">Newsletter</div>
      <div className="long-field">
        <TextInput
          type="email"
          placeholder="Enter your email"
          value={email}
          required={true}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
    </form>
  );
};

export default NewsletterForm;
