import "./Home.scss";

import { Col, Container, Row } from "reactstrap";
import React, { useState } from "react";
import { animated, useSpring } from "react-spring";

import AriesModelImage from "assets/img/home/Aries.jpg";
import BestSellers from "components/BestSellers";
import FeaturedBlogShowcase from "components/FeaturedBlogShowcase";
import FeaturedProduct from "components/FeaturedProduct";
import Header from "components/Header";
import HeaderImage from "assets/img/home/header.jpg";
import VisibilitySensor from "react-visibility-sensor";
import WeeklyHoroscopeImage from "assets/img/home/horoscope.jpg";
import { connect } from "react-redux";
import { fetch as fetchBlog } from "../../redux/actions/blogActions";
import { useEffect } from "react";

const Home = ({ blog, fetchBlog }) => {
  // Fetch entries
  useEffect(() => {
    if (!blog.entries.length) {
      fetchBlog();
    }
  }, [blog.entries.length, fetchBlog]);

  // Animation
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const animations = [
    {
      fadeIn: useSpring({
        opacity: isVisible1 ? 1 : 0,
      }),
      slideUp: useSpring({
        position: "relative",
        top: isVisible1 ? "0" : "20px",
      }),
      slideLeft: useSpring({
        position: "relative",
        left: isVisible1 ? "0" : "20px",
      }),
    },
    {
      fadeIn: useSpring({
        opacity: isVisible2 ? 1 : 0,
      }),
      slideUp: useSpring({
        position: "relative",
        top: isVisible2 ? "0" : "20px",
      }),
      slideDown: useSpring({
        position: "relative",
        bottom: isVisible2 ? "0" : "20px",
      }),
    },
  ];

  return (
    <>
      <Header
        image={HeaderImage}
        title={
          <>
            Portable Rolling
            <br />
            Oil Perfume
          </>
        }
        subtitle="The new collection of perfumes. A sensation of freshness. A journey in every roll."
      >
        <a href="/shop" className="btn btn-left">
          Discover
        </a>
      </Header>
      <Container className="space">
        <Row className="justify-content-center">
          <Col lg="7">
            <VisibilitySensor
              onChange={setIsVisible1}
              partialVisibility
              minTopValue="200"
            >
              <animated.div
                className="collection space-bigger"
                style={animations[0].fadeIn}
              >
                <div className="collection-image">
                  <img src={AriesModelImage} alt="Aries Model" />
                </div>
                <div className="collection-text">
                  <div className="collection-text-inner">
                    <div className="text-right">
                      <animated.div
                        className="text-uppercase text-smaller"
                        style={animations[0].slideLeft}
                      >
                        Collection
                      </animated.div>
                      <animated.h3
                        className="space"
                        style={animations[0].slideLeft}
                      >
                        Sugar & Pepper — the ‘Zodiac’ Collection
                      </animated.h3>
                      <animated.div
                        className="collection-button"
                        style={animations[0].slideLeft}
                      >
                        <a href="/shop" className="btn btn-right">
                          Shop now
                        </a>
                      </animated.div>
                    </div>
                  </div>
                </div>
              </animated.div>
            </VisibilitySensor>
          </Col>
          <Col lg="5">
            <VisibilitySensor
              onChange={setIsVisible2}
              partialVisibility
              minTopValue="500"
            >
              <animated.div
                className="space-bigger text-center"
                style={animations[1].fadeIn}
              >
                <animated.div style={animations[1].slideDown}>
                  <img src={WeeklyHoroscopeImage} alt="Weekly Horoscope" />
                </animated.div>
                <animated.div style={animations[1].slideUp}>
                  <h3 className="space">Weekly Horoscope</h3>
                  <a href="/horoscope" className="btn">
                    Discover
                  </a>
                </animated.div>
              </animated.div>
            </VisibilitySensor>
          </Col>
          <Col xs="12">
            <BestSellers />
            <FeaturedProduct />
            <FeaturedBlogShowcase />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default connect(
  (state) => ({
    blog: state.blog,
  }),
  {
    fetchBlog,
  }
)(Home);
