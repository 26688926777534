import './NotFound.scss';

import { Container } from 'reactstrap';
import Image404 from 'assets/img/404/404.png';
import React from 'react';

const NotFound = () => {
  return (
    <div className="notfound-section">
      <Container className="space-bigger">
        <h1 className="notfound-title space-bigger">
          404 Page Not Found
        </h1>
        <img src={Image404} alt="404 not found" className="notfound-artwork" />
      </Container>
    </div>
  );
}

export default NotFound;