import "./UserBadge.scss";

import React from "react";

const UserBadge = ({ user }) => {
  const { firstname, lastname, email, image } = user;

  return (
    <div className="user-badge space">
      <a href={"mailto:" + email}>
        <img
          src={image}
          alt={firstname + " " + lastname}
          className="user-image"
        />
        {firstname + " " + lastname}
      </a>
    </div>
  );
};

export default UserBadge;
