import './AboutSlide.scss';

import React, { useRef } from 'react';

import ArrowLeftIcon from 'assets/icon/arrow-left.svg';
import ArrowRightIcon from 'assets/icon/arrow-right.svg';
import Slider from 'react-slick';
import { aboutSlides } from 'samples/about-slides';

const renderSlides = (slides, slideRef) => {
  return slides.map((slide, i) => {
    let nextSlide = (i + 1 === slides.length) ? slides[0] : slides[i + 1];
    const { title, img } = slide;

    return (
      <div className="fullscreen" key={ i }>
        <div className="background-image full-cover" style={{
          backgroundImage: 'url(' + img[0] + ')'
        }}>
          <div className="full-cover overlay">
            <h1 className="about-slide-text text-white">
              { title }
            </h1>
            <div className="about-slide-readmore">
              <button type="button" className="btn btn-left btn-white">
                Read more
              </button>
            </div>
            <div className="about-slide-preview">
              <div className="left-side">
                <div className="left-side-inner">
                  <div className="text-uppercase text-muted text-smaller">
                    Up next
                  </div>
                  <div className="space">
                    <h5>
                      { nextSlide.summary }
                    </h5>
                  </div>
                  <button type="button" className="arrow" onClick={ () => slideRef.current.slickNext() }>
                    <img src={ ArrowRightIcon } alt="Arrow right icon"/>
                  </button>
                </div>
              </div>
              <div className="right-side background-image" style={{
                backgroundImage: 'url(' + nextSlide.img[1] + ')'
              }}></div>
            </div>
          </div>
        </div>
      </div>
    );
  });
}

const AboutSlide = () => {
  // Slick slider settings
  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 8000,
    fade: true,
    infinite: true,
    speed: 1300,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnFocus: false,
    pauseOnHover: false,
  };

  const slideRef = useRef(null);

  const prevSlide = () => {
    slideRef.current.slickPrev();
  };

  const nextSlide = () => {
    slideRef.current.slickNext();
  };

  const slides = renderSlides(aboutSlides, slideRef);
  
  return (
    <div className="about-slide">
      <Slider ref={ slideRef } {...settings}>
        { slides }
      </Slider>
      <div className="about-slide-arrows">
        <button type="button" className="arrow" onClick={ () => prevSlide() }>
          <img src={ ArrowLeftIcon } alt="Arrow left icon"/>
        </button>
        <button type="button" className="arrow" onClick={ () => nextSlide() }>
          <img src={ ArrowRightIcon } alt="Arrow right icon"/>
        </button>
      </div>
    </div>
  );
}

export default AboutSlide;