import { RECEIVE_ENTRIES } from "../actions/types";

const initialState = {
  entries: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RECEIVE_ENTRIES:
      return {
        ...state,
        entries: action.payload,
      };

    default:
      return state;
  }
}
